import * as React from "react";
import { SVGProps } from "react";

const MortierIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 230 230"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#ffffff50;}"}</style>
    </defs>
    <path
      className="cls-1"
      d="m33.98,90.49c-1.4.32-5.05.6-7.07.63-1.31.02-2.51-.06-3.58-.19-1.17-.15-2.09.98-1.73,2.1.79,2.42,1.88,5.17,3.41,8.07,2.31,4.38,6.8,7.75,8.97,10.19v22.65c0,35.44,28.73,64.17,64.17,64.17h42.95c35.44,0,64.17-28.73,64.17-64.17v-40.48c0-1.64-1.33-2.97-2.97-2.97H33.98Z"
    />
    <path
      className="cls-1"
      d="m196.88,31.48h0c-6.62-4.56-15.71-2.69-19.99,4.12l-30.59,48.64h29.3l24.55-32.62c4.84-6.42,3.35-15.58-3.27-20.14Z"
    />
    <rect className="cls-1" x={92.08} y={203.93} width={54.19} height={3.03} />
  </svg>
);
export default MortierIcon;
