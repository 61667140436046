import React from "react";

import { Typography } from "@mui/material";

import DevisIconActif from "../../../../Assets/svg/menu/DevisIcon";
import CustomHeader from "../CustomHeader/CustomHeader";

const HeaderDevis: React.FC = () => (
  <CustomHeader
    childLeft={
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ fontSize: 24, fontWeight: "bold", color: "#ffffff" }}
        >
          Devis
        </Typography>
      </div>
    }
    icon={
      <DevisIconActif
        viewBox="100 0 80 130"
        fill="#ffffff50"
        style={{
          height: "138px",
          width: "425px",
        }}
      />
    }
  />
);

export default HeaderDevis;
