import { gql } from "@apollo/client";

export const CREATE_DEVIS = gql`
  mutation CreateDevis($input: InputCreateDevis!) {
    CreateDevis(input: $input) {
      devisId
    }
  }
`;

export const UPDATE_VALIDATE_DEVIS = gql`
  mutation UpdateValidateDevis($input: InputUpdateValidateDevis!) {
    UpdateValidateDevis(input: $input) {
      id
      id_typecommande
      id_client
      datecommande
      dateheurecommande
      num_preparation
      data
      img
      typecomande {
        id
        nom
      }
      renouvellement {
        rrule
        id
        taskid
        date
      }
    }
  }
`;
