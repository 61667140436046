import React from "react";

import { Typography } from "@mui/material";
import { BiPlusMedical } from "react-icons/bi";

import useStyles from "./style";

const Adress: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <BiPlusMedical className={styles.icon} />
      <div className={styles.boxText}>
        <Typography classes={{ root: styles.name }} flexWrap="wrap">
          PHCIE.TEXT (DONC NE PAS FAIRE DE PREP)
        </Typography>
        <Typography className={styles.address}>
          42 rue de Sébastien D.,42100 SAINT-ETIENNE
        </Typography>
      </div>
    </div>
  );
};

export default Adress;
