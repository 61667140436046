import React from "react";

import { useMutation } from "@apollo/client";
import { Button, Icon, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import {
  Mutation,
  MutationResetPasswordArgs,
} from "../../graphql/__generated__/types";
import { RESET_PASSWORD } from "../../graphql/resetPassword/mutation";

interface InputFormValues {
  otp: string;
  password: string;
  confirmPassword: string;
}

const defaultValues: InputFormValues = {
  otp: "",
  confirmPassword: "",
  password: "",
};

const ResetPassword: React.FC = () => {
  const {
    state: { idUser: id },
  } = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<InputFormValues>();
  const [DoResetPassword, { loading }] = useMutation<
    Mutation,
    MutationResetPasswordArgs
  >(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.resetPassword?.id) {
        reset(defaultValues);
      }
    },
  });

  const handleResetPassword = (data: InputFormValues) => {
    const { otp, password } = data;
    DoResetPassword({
      variables: {
        input: {
          id: Number(id),
          otp,
          password,
        },
      },
    });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading loading={loading} />
      <Icon title="unlock" color="error" />
      <Typography>Réinitialiser votre mot de passe ?</Typography>
      <Typography>Veuillez saisir votre nouveau mot de passe</Typography>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="otp *"
            label="Code de reinitialisation *"
            fullWidth={false}
            style={{ width: "50%" }}
            onChange={onChange}
            value={value}
            helperText={errors.otp?.message}
            error={!!errors.otp}
          />
        )}
        name="otp"
        rules={{
          maxLength: {
            value: 6,
            message: "Ce champ doit contenir 6 caracteres",
          },
          minLength: {
            value: 6,
            message: "Ce champ doit contenir 6 caracteres",
          },
          required: "Ce champ est requis .",
        }}
        defaultValue=""
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="newpass *"
            label="Nouveau mot de passe"
            type="password"
            fullWidth={false}
            style={{ width: "50%" }}
            onChange={onChange}
            value={value}
            helperText={errors.password?.message}
            error={!!errors.password}
          />
        )}
        name="password"
        rules={{
          required: "Le mot de passe est requis",
        }}
        defaultValue=""
      />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="otp *"
            label="Confirmation de mot de passe"
            fullWidth={false}
            style={{ width: "50%" }}
            type="password"
            onChange={onChange}
            value={value}
            helperText={errors.confirmPassword?.message}
            error={!!errors.confirmPassword}
          />
        )}
        name="confirmPassword"
        rules={{
          validate: (value) =>
            value === getValues("password") ||
            "Les mots de passe ne correspondent pas",
        }}
        defaultValue=""
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(handleResetPassword)}
      >
        Reinitialiser
      </Button>
    </div>
  );
};

export default ResetPassword;
