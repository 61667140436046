import React from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

import { stylesX } from "./style";
import { useStyles } from "./styles";

interface ICustomButtonDashboard {
  icon: any;
  name: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  background?: string;
  child?: {
    label: string;
    onclick: () => void;
    activID: string;
  }[];
}
const CustomButtonDashboard: React.FC<ICustomButtonDashboard> = ({
  icon,
  onClick,
  name,
  background = "#616161",
  child,
}) => {
  const { activ } = useParams();
  const [openChild, setopenChild] = React.useState(false);
  const style = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <Button
        sx={[stylesX.container]}
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          child && setopenChild((prev) => !prev);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          onClick && onClick(event);
        }}
      >
        {icon}
        <p
          className={style.text}
          style={{
            color: background,
            fontSize: "12px",
            fontWeight: background === "#616161" ? "normal" : "bold",
            textTransform: "none",
          }}
        >
          {name}
        </p>
        {child && (
          <div style={{ display: "flex", marginLeft: "5px" }}>
            {openChild ? (
              <ExpandLessIcon color="action" fontSize="medium" />
            ) : (
              <ExpandMoreIcon color="action" fontSize="medium" />
            )}
          </div>
        )}
      </Button>
      {child && (
        <div className={style.btnChild}>
          {openChild &&
            child.map((item) => {
              return (
                <Button
                  key={item.activID}
                  onClick={item.onclick}
                  style={{
                    fontSize: "10px",
                    lineHeight: "10px",
                    textAlign: "start",
                    textTransform: "unset",
                    color: activ === item.activID ? background : "#616161",
                    fontWeight: activ === item.activID ? "bold" : "normal",
                  }}
                >
                  {item.label}
                </Button>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default CustomButtonDashboard;
