import React from "react";

import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import {
  Query,
  QueryGetFactureByIdArgs,
} from "../../graphql/__generated__/types";
import { GET_FACTURES_BY_ID } from "../../graphql/factures/query";

const FactureView: React.FC = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<Query, QueryGetFactureByIdArgs>(
    GET_FACTURES_BY_ID,
    {
      fetchPolicy: "cache-first",
      variables: {
        id: Number(id),
      },
    },
  );

  if (!loading && !data?.GetFactureById) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f0f0f0",
        }}
      >
        <div style={{ color: "#333", textAlign: "center" }}>
          <span role="img" aria-label="sad face" style={{ fontSize: "5rem" }}>
            😢
          </span>
          <h1 style={{ fontSize: "2rem", marginTop: "0.5rem" }}>
            Page indisponible
          </h1>
        </div>
      </div>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      loading="lazy"
      src={data?.GetFactureById?.url || undefined}
      width="100%"
      height="100vh"
      style={{ height: "100vh" }}
    />
  );
};

export default FactureView;
