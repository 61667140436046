import * as React from "react";
import { SVGProps } from "react";

const HomeIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 230 230"
    {...props}
  >
    <defs>
      <style>{".hom{fill:#616161;}"}</style>
    </defs>
    <path
      className="hom"
      d="m202.23,221.79H32.24c-1.81,0-3.28-1.47-3.28-3.28V82.91c0-1.32.79-2.46,1.92-2.98L115.21,8.91c1.22-1.03,3.01-1.03,4.22,0l84.79,71.4c.08.07.16.13.24.21.93.81,1.32,2.04,1.05,3.22v134.78c0,1.81-1.47,3.28-3.28,3.28h0Zm-166.72-6.56h163.44V84.45L117.32,15.7,35.51,84.59v130.65h0Z"
    />
    <path
      className="hom"
      d="m159.25,153.11h-19.24c-3.32,0-6,2.69-6,6v19.24c0,3.32-2.69,6-6,6h-22.09c-3.32,0-6-2.69-6-6v-19.24c0-3.32-2.69-6-6-6h-19.24c-3.32,0-6-2.69-6-6v-22.09c0-3.32,2.69-6,6-6h19.24c3.32,0,6-2.69,6-6v-19.24c0-3.32,2.69-6,6-6h22.09c3.32,0,6,2.69,6,6v19.24c0,3.32,2.69,6,6,6h19.24c3.32,0,6,2.69,6,6v22.09c0,3.32-2.69,6-6,6h0Z"
    />
  </svg>
);
export default HomeIcon;
