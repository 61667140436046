import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  palette: {
    primary: {
      main: process.env.REACT_APP_COLOR_PRIMARY_MAIN || "#65C4C3",
      light: "#f2f2f2",
      dark: process.env.REACT_APP_COLOR_PRIMARY_DARK || "#FFFFFF",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#00000075",
    },
    text: {
      primary: "#000000",
      disabled: "#00000050",
    },
  },
});

export default theme;
