export const SetDataToLocalStorage = (key: string, value: any) => {
  const newvalue = typeof value === "string" ? value : JSON.stringify(value);
  return localStorage.setItem(key, newvalue);
};

export const GetDataFromLocalStorage = (key: string): string | null => {
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result);
  }
  return null;
};
