import * as React from "react";
import { SVGProps } from "react";

const ContactIconHeader: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = "#fff" } = props;
  return (
    <svg
      width={230}
      height={230}
      viewBox="0 0 230 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_132_14)">
        <path
          d="M114.87 196.229C102.794 195.753 91.1551 193.851 79.7773 189.958C78.9927 189.699 77.7721 189.872 77.0746 190.348C62.9069 200.165 47.3006 204.576 30.0815 203.278C27.6839 203.105 26.1145 201.851 25.8966 199.948C25.6786 198.002 26.812 196.662 29.2968 195.883C32.3483 194.932 34.5279 192.856 36.3152 190.348C40.6309 184.336 42.8978 177.417 44.5543 170.324C44.7287 169.589 44.0748 168.422 43.4645 167.816C32.0867 156.572 24.0657 143.382 20.7962 127.726C15.783 103.768 21.3193 82.1011 36.7076 63.1158C51.8343 44.4765 71.8434 33.6215 95.4272 29.3833C121.365 24.7127 145.995 28.6482 168.75 42.0979C186.275 52.4339 199.44 66.7918 206.24 86.0366C215.438 112.201 211.21 136.635 194.383 158.605C179.605 177.893 159.509 189.05 135.751 193.678C128.863 195.018 121.801 195.408 114.826 196.229H114.87ZM41.5028 195.883C42.3311 195.883 42.8542 195.926 43.3337 195.883C54.9294 194.326 65.4353 190.175 74.6334 182.909C76.1155 181.742 77.5977 181.309 79.4722 182.044C96.9093 188.575 114.826 190.261 133.135 186.845C154.844 182.779 173.371 173.006 187.321 155.794C201.227 138.711 206.545 119.207 201.925 97.5402C197.652 77.6034 186.057 62.3806 169.23 51.1364C147.608 36.6488 123.632 32.1079 98.0427 36.3461C75.4616 40.1085 56.4987 50.4013 41.9823 68.2189C23.281 91.1829 21.2321 122.84 36.8384 147.923C41.0669 154.712 46.2108 160.724 52.3138 165.913C54.973 168.205 54.973 170.368 52.3138 172.53C51.8343 172.919 51.3984 173.481 51.2676 174.044C49.3059 181.785 46.516 189.137 41.4592 195.883H41.5028Z"
          fill="white"
        />
        <path
          d="M116.231 123.5C110.037 123.5 105.199 118.974 105.154 113.124C105.154 107.317 110.263 102.493 116.366 102.578C122.47 102.663 127.308 107.36 127.308 113.167C127.308 119.017 122.47 123.5 116.231 123.5Z"
          fill="white"
        />
        <path
          d="M159.88 123.457C154.073 123.457 149.461 118.846 149.461 113.081C149.461 107.359 154.201 102.62 159.88 102.577C165.687 102.577 170.427 107.317 170.384 113.209C170.384 119.016 165.773 123.5 159.88 123.5V123.457Z"
          fill="white"
        />
        <path
          d="M83 113.146C83 119.136 78.4738 123.542 72.3676 123.5C66.7312 123.457 62.1196 118.794 62.0769 113.146C62.0769 107.371 66.9447 102.451 72.6665 102.579C78.4311 102.665 83 107.371 82.9573 113.146H83Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_132_14">
          <rect width={230} height={230} fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactIconHeader;
