import { createContext } from "react";

interface IImageContext {
  imageurl: string | ArrayBuffer | null;
  setImageUrl: (image: string | ArrayBuffer | null) => void;
}
const ImageContext = createContext<IImageContext>({
  imageurl: "",
  setImageUrl: () => {},
});

export default ImageContext;
