import React from "react";

import { Typography } from "@mui/material";

import theme from "../../../Theme/theme";

interface ICustomField {
  label: string;
  value: String | undefined;
}
const CustomField: React.FC<ICustomField> = (props) => {
  const { label, value } = props;
  return (
    <div
      style={{
        display: "flex",
        width: "350px",
        padding: "21px 0px 20px",
        flexDirection: "column",
        textAlign: "left",
        alignItems: "start",
      }}
    >
      <Typography
        style={{
          fontSize: "18px",
          color: theme.palette.primary.main,
          fontWeight: "800",
          padding: "0 15px 0 15px",
        }}
      >
        {label}
      </Typography>
      <Typography
        style={{
          fontWeight: "600",
          height: "52px",
          width: "323px",
          background: "#F9F9F9",
          borderRadius: "13px",
          display: "flex",
          paddingLeft: "10px",
          alignItems: "center",
        }}
      >
        {value}
      </Typography>
    </div>
  );
};

export default CustomField;
