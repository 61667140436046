import React from "react";

import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import CustomField from "./CustomField";
import { useStyles } from "./style";

interface ICardHistorique {
  patientName?: string;
  field: string;
  numero: number;
  date: string;
  status?: string;
  statusColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  price?: { label: string; value: string }[];
  isValidated?: boolean;
  enDate?: string;
  indication?: string;
}
const CardHistorique: React.FC<ICardHistorique> = (props) => {
  const { activ } = useParams();
  const {
    field,
    numero,
    date,
    price,
    status,
    statusColor = "red",
    onClick,
    isValidated,
    enDate,
    patientName,
    indication,
  } = props;
  const style = useStyles();
  const newField = activ === "1" ? "Plannification Commande" : field;
  return (
    <div className={style.container}>
      <div className={style.root}>
        <table style={{ width: "100%" }}>
          <tr>
            <td style={{ textAlign: "start" }}>
              <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                {`${newField} : ${numero}`}
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <div className={style.boxDateCreated}>
                <CustomField label="Date du création" value={date || ""} />
                <CustomField
                  label="Nom du patient"
                  value={patientName || "-"}
                />
                <CustomField
                  label="Formule de la commande"
                  value={indication || "-"}
                />
              </div>
            </td>

            {price && (
              <td style={{ verticalAlign: "top" }}>
                <div className={style.boxPrice}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      font: "normal normal medium 14px/21px Roboto",
                      color: "#909090",
                    }}
                  >
                    prix:
                  </Typography>
                  {price.map((item) => {
                    return (
                      <div
                        key={item.label}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "168px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography color="#909090" fontSize="14px">
                          {item.label}:
                        </Typography>
                        <Typography>{item.value}</Typography>
                      </div>
                    );
                  })}
                </div>
              </td>
            )}

            {status && (
              <td style={{ verticalAlign: "top" }}>
                <Typography style={{ color: "#909090", fontSize: "14px" }}>
                  Statut
                </Typography>
                <Typography color={statusColor}>{status}</Typography>
              </td>
            )}

            {activ === "2" && (
              <td style={{ verticalAlign: "top" }}>
                <Typography style={{ color: "#909090", fontSize: "14px" }}>
                  Statut du devis
                </Typography>
                <Typography
                  style={{
                    color: isValidated ? "#17A559" : "#FE3434",
                  }}
                >
                  {isValidated ? "validé" : "À valider"}
                </Typography>
              </td>
            )}
            {activ === "1" && enDate && (
              <td style={{ verticalAlign: "top" }}>
                <Typography style={{ color: "#909090", fontSize: "14px" }}>
                  Date de fin
                </Typography>
                <Typography>{enDate}</Typography>
              </td>
            )}
          </tr>
        </table>
      </div>

      <div style={{ width: "25%" }}>
        <Button
          style={{
            fontSize: "14px",
            color: "#909090",
            textTransform: "unset",
          }}
          onClick={onClick}
        >
          Voir détails
        </Button>
      </div>
    </div>
  );
};

export default CardHistorique;
