import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  text: {
    // fontSize: 16,
    textTransform: "capitalize",
    padding: "0px 0 0 20px",
  },
  btnChild: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    paddingLeft: "40%",
  },
});
