export const stylesX = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "0",
    width: "100%",
    padding: "0px 0 0 40px",
    height: 50,
  },
  text: {
    fontSize: 16,
    color: "#3A3A3A",
    textTransform: "capitalize",
    padding: 17,
  },
};
