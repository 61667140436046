import theme from "../../Theme/theme";

export const stylesX = {
  container: {
    // padding: "20px",
    width: "100%",
    background: theme.palette.primary.contrastText,
    borderTopLeftRadius: "42px",
    boxShadow: "0px -12px 6px #00000029",
    height: "calc(100vh - 138px)",
    "@media (max-width: 508px)": {
      marginBottom: "45px",
    },
  },
  menu: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  label: {
    display: {
      md: "flex",
      xs: "none",
    },
    marginTop: "5px",
    textTransform: "capitalize",
    letterSpacing: 2,
  },
  box: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "20px 0 0 20px",
  },
  title: {
    color: "rgb(97, 97, 97)",
    fontWeight: "600",
    fontSize: "24px",
  },
};
