import React, { useContext } from "react";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import CustomSnackBar from "../Components/CustomSnackBar/CustomSnackBar";
import AuthContext from "../Context/AuthContext";
import { API } from "./constant";

interface ICreateClient {
  children: React.ReactNode | React.ReactNode[] | null;
}

// eslint-disable-next-line react/prop-types
export const CreateClient: React.FC<ICreateClient> = ({ children }) => {
  const [open, setopen] = React.useState<boolean>(false);
  const [message, setmessage] = React.useState<string>("");

  const { token, logout } = useContext(AuthContext);
  const TOKEN = token || localStorage.getItem("token");
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const err of graphQLErrors) {
        setmessage(err.message);
        setopen(true);
        switch (err.extensions.code) {
          case "UNAUTHENTICATED": {
            logout();
            break;
          }
          case "INTERNAL_SERVER_ERROR": {
            break;
          }
          case "GRAPHQL_VALIDATION_FAILED": {
            break;
          }
          case "UNAUTHORIZED": {
            logout();
            break;
          }
          default:
            setopen(false);
            break;
        }
      }
    }
    if (networkError) {
      setmessage(networkError.message);
    }
  });
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: TOKEN ? `bearer ${TOKEN}` : "",
      },
    }));

    return forward(operation);
  });
  const httpLink = new HttpLink({ uri: API });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, authMiddleware, httpLink]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "cache-first",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

  return (
    <ApolloProvider client={client}>
      <CustomSnackBar
        message={message}
        open={open}
        setOpen={setopen}
        severity="error"
      />
      {children}
    </ApolloProvider>
  );
};
