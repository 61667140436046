import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "10px 21px 10px 0",
    flexDirection: "row",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1",
    justifyContent: "space-between",
  },
  headerBox: {
    display: "flex",
    alignItems: "flex-start",
    flex: "1",
    width: "100%",
  },
  boxDateCreated: {
    margin: "0px 50px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "flex-start",
  },
  boxPrice: {
    margin: "0px 62px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  boxStatus: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
  },
  textIndication: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "wrap",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    textAlign: "left",
    width: "255px",
    wordWrap: "break-word",
  },
});

export const styleCustomField = makeStyles({
  text: {
    color: "#909090",
    fontSize: "16px",
    letterSpacing: "-0.32px",
    textAlign: "start",
    "@media (max-width:1200px)": {
      fontSize: "14px",
    },
  },
});
// export const styleX={}
