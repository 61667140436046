import React from "react";

import ImageContext from "../Context/ImageContext";

interface IimageProvider {
  children: React.ReactNode | JSX.Element;
}
const ImageProvider: React.FC<IimageProvider> = ({ children }) => {
  const [imageurl, setImage] = React.useState<string | ArrayBuffer | null>(
    null,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeImage = (image: string | ArrayBuffer | null) => {
    setImage(image);
  };
  const value = React.useMemo(
    () => ({ imageurl, setImageUrl: handleChangeImage }),
    [imageurl, handleChangeImage],
  );
  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};

export default ImageProvider;
