import { makeStyles } from "@mui/styles";

export const stylesx = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
  },
  boxColumn: {
    flexDirection: {
      md: "row",
      xs: "column",
    },
    display: "flex",
    justifyContent: "space-around",
  },
  paddingBox: { padding: "25px" },
  img: {
    padding: "10px",
    borderRadius: "16px",
    height: "300px",
    width: "300px",
    "@media (max-width: 508px)": {
      height: "100px",
      width: "200px",
      padding: "0px",
    },
  },
  button: {
    margin: "10px",
    textTransform: "unset",
    width: "250px",
    "@media (max-width: 508px)": { marginBottom: "76px" },
  },
};
export const useStyle = makeStyles({
  container: {
    flex: "1",
    background: "#ffffff",
    width: "100%",
    borderTopLeftRadius: "26px",
    borderTopRightRadius: "26px",
  },
});
