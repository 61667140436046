import * as React from "react";
import { SVGProps } from "react";

const SuiviIconActif: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = "#616161" } = props;
  return (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 230 230"
      {...props}
    >
      <defs>
        <style>{`.suiviActif{fill:${fill};}`}</style>
      </defs>
      <path
        className="suiviActif"
        d="m114.19,158.31c-1.44-13.8,4-30.71,14.54-45.24h0c10.57-14.57,22.13-28.99,36.84-31.29,8.03-1.26,16.04,1.14,24.5,7.33,11.32,8.29,14.13,41.03-3.79,65.73-9.27,12.78-21.57,22.45-33.73,26.53-8.05,2.7-20.39-4.62-23.98-6.93-3.42-2.2-10.69-7.34-13.38-12.87-.53-1.08-.88-2.18-.99-3.27h-.01Zm71.44-63.13c-6.76-4.95-12.94-6.9-18.89-5.97-10.67,1.67-20.48,12.52-31.92,28.28h0c-9.48,13.07-14.4,28.04-13.15,40.04.17,1.53,3.76,5.95,10.96,10.58,8.05,5.18,14.91,7,17.52,6.12,10.72-3.6,21.66-12.28,30.04-23.82,13.44-18.52,13.45-41.16,8.59-51.11-.9-1.85-1.97-3.26-3.15-4.12h0Z"
      />
      <path
        className="suiviActif"
        d="m93.33,171.25h0c4.12-5.67,7.67-8.27,11.54-8.44,4.98-.22,8.38,3.7,11.98,7.85,2.15,2.48,4.59,5.29,7.53,7.43,2.94,2.13,6.37,3.58,9.39,4.86,5.06,2.14,9.84,4.15,11.18,8.96,1.04,3.73-.33,7.92-4.45,13.59-6.02,8.29-14.04,14.42-22.59,17.27-8.99,2.99-17.53,2.05-24.02-2.67-3.06-2.22-5.41-5.12-7.05-8.49-5.36-10.99-3.23-26.96,6.48-40.35h0Zm37.52,18.63c-3.4-1.43-7.25-3.06-10.89-5.7s-6.38-5.8-8.8-8.59c-2.17-2.5-4.62-5.33-5.97-5.27-.46.02-2.23.46-5.77,5.34h0c-10.26,14.13-10.75,31.33-1.1,38.33,4.48,3.25,10.6,3.83,17.22,1.62,7.07-2.35,13.77-7.52,18.88-14.55,3.54-4.88,3.41-6.7,3.29-7.14-.02-.06-.04-.12-.07-.18-.61-1.26-3.89-2.64-6.79-3.86Z"
      />
      <path
        className="suiviActif"
        d="m119.44,77.51c-9.22,15.12-22.04,25.61-34.02,29.2-7.32,2.2-30.53-13.6-31.22-23.19-.83-11.6,2.82-25.78,10.96-39.12,14.86-24.36,43.83-33.72,54.04-27.55,24.75,14.96,15.1,36.3.24,60.66Z"
      />
      <path
        className="suiviActif"
        d="m80.11,131.62c-10.19,16.71-28.15,24.33-40.12,17.03-11.97-7.3-13.41-26.76-3.22-43.47,10.19-16.71,14.36-1.72,26.33,5.58,11.97,7.3,27.21,4.14,17.02,20.85h-.01Z"
      />
    </svg>
  );
};
export default SuiviIconActif;
