import React from "react";

import { Box, Typography } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";

import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
// eslint-disable-next-line import/no-cycle
import { FormOrder } from "../AddCommand/AddCommand";
import { stylesx } from "../AddCommand/style";
import CustomSelect from "../common/CustomSelect";

interface IinfoClient {
  errors: FieldErrors<FormOrder>;
  control: Control<FormOrder, any>;
}
const InfoClient: React.FC<IinfoClient> = (props) => {
  const { control, errors } = props;

  const TypePatient = [
    { label: "Nourrisson - < 30 mois", value: "4" },
    { label: "Pédiatrie - 0 à 15 ans", value: "1" },
    { label: "Adulte", value: "2" },
    { label: "Vétérinaire", value: "0" },
  ];

  return (
    <Box sx={stylesx.paddingBox}>
      <Typography variant="h6">Information Client</Typography>
      <Controller
        name="doctorName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="prescription"
            label="Nom du prescripteur"
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="patientName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="patient"
            label="Nom du patient"
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="typePatient"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Ce champ est requis",
          },
        }}
        render={({ field: { value, onChange } }) => (
          <CustomSelect
            label="Type de patient"
            data={TypePatient}
            setValue={onChange}
            value={value}
            errors={!errors.typePatient}
            errorLabel={errors.typePatient?.message}
          />
        )}
      />
    </Box>
  );
};

export default InfoClient;
