import React from "react";

import { useLazyQuery } from "@apollo/client";
import { Box, Divider, Skeleton } from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import Empty from "../../../Components/EmptyData/Empty";
import {
  Query,
  QueryGetListCommandesArgs,
} from "../../../graphql/__generated__/types";
import { GET_ALL_ORDERS } from "../../../graphql/command/query";
import CardHistorique from "../Common/Feature/CardHistorique";
import { stylesx } from "./style";

moment.locale("fr-FR");
const Historique: React.FC = () => {
  const { activ } = useParams();
  const navigate = useNavigate();
  const [getDevisById, { data, loading }] = useLazyQuery<
    Query,
    QueryGetListCommandesArgs
  >(GET_ALL_ORDERS, {
    fetchPolicy: "cache-first",
  });

  React.useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (activ && !isNaN(Number(activ))) {
      getDevisById({
        variables: {
          input: {
            skip: 0,
            take: 50,
            TypeCommandes: Number(activ),
          },
        },
      });
    }
  }, [activ, getDevisById]);

  return (
    <Box sx={stylesx.root}>
      {!loading &&
        data?.GetListCommandes?.map((item) => {
          const info = JSON.parse(item?.data);
          // eslint-disable-next-line no-nested-ternary
          const field = info.fieldname
            ? info.fieldname
            : info.type_commande === 2
            ? "Devis n°"
            : "Commande n°";
          const enDate = item?.renouvellement?.rrule
            ? JSON.parse(item?.renouvellement?.rrule)
                .dateRepeatList?.slice(-1)
                .shift()
            : undefined;
          return (
            <div style={{ background: "#FFF" }} key={item?.id}>
              <CardHistorique
                patientName={info.Nom_du_patient}
                indication={info.Indications}
                key={`${item?.id}s`}
                date={moment(item?.datecommande).format("Do MMM YYYY")}
                field={field}
                numero={item?.id || 0}
                price={info.price}
                status={info.status}
                statusColor={info.colorstatus}
                isValidated={!!info?.isValidated}
                enDate={
                  enDate && moment(new Date(enDate)).format("Do MMM YYYY")
                }
                onClick={() => item?.id && navigate(item?.id.toString())}
              />
              <Divider style={{ borderWidth: 1, background: "#DFDFDF" }} />
            </div>
          );
        })}
      {!loading && !data?.GetListCommandes?.length && (
        <Empty {...{ isCanBack: false }} />
      )}
      {loading && (
        <>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </>
      )}
    </Box>
  );
};

export default Historique;
