import { makeStyles } from "@mui/styles";

import theme from "../../Theme/theme";

export const useStyle = makeStyles({
  container: {
    // padding: "0px 0 0 157px",
    flex: "1",
    width: "100%",
    display: "flex",
    background: "#ffffff",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderTopLeftRadius: "24px",
    boxShadow: "0px -12px 6px #00000029",
  },
  name: { fontSize: "18px", fontWeight: "600" },
  boxEdit: {
    position: "fixed",
    bottom: "15px",
    right: "23px",
    background: theme.palette.primary.main,
    borderRadius: "10px",
  },
});
