import React from "react";

import { Box, Typography } from "@mui/material";
import { Control, Controller, FieldErrors } from "react-hook-form";

import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
// eslint-disable-next-line import/no-cycle
import { FormOrder } from "../AddCommand/AddCommand";
import { stylesx } from "../AddCommand/style";

interface IInfoComplementaire {
  control: Control<FormOrder, any>;
  isRequired: boolean;
  errors: FieldErrors<FormOrder>;
}
const InfoComplementaire: React.FC<IInfoComplementaire> = (props) => {
  const { control, isRequired, errors } = props;
  return (
    <Box sx={stylesx.paddingBox}>
      <Typography variant="h6">Information complementaire</Typography>
      <Controller
        name="pharmaceuticalForm"
        control={control}
        rules={{
          required: {
            value: isRequired,
            message: "Ce champ est requis",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            error={isRequired}
            color="secondary"
            id="galénique"
            label="Forme galénique"
            multiline
            rows={3}
            value={value}
            onChange={onChange}
            helperText={errors.pharmaceuticalForm?.message}
          />
        )}
      />
      <Controller
        name="orderFormule"
        control={control}
        rules={{
          required: {
            value: isRequired,
            message: "Ce champ est requis",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            error={isRequired}
            id="commande"
            value={value}
            label="Formule de la commande"
            rows={3}
            multiline
            onChange={onChange}
            helperText={errors.orderFormule?.message}
          />
        )}
      />
      <Controller
        name="comment"
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="commentaire"
            label="Commentaire"
            rows={3}
            onChange={onChange}
            multiline
            value={value}
          />
        )}
      />
    </Box>
  );
};

export default InfoComplementaire;
