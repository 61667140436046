/* eslint-disable no-nested-ternary */
import React, { useContext } from "react";

import { Button, Typography } from "@mui/material";
import { Outlet, useLocation, useParams } from "react-router-dom";

import image from "../../Assets/img/profil.png";
import Logo from "../../Components/Logo/Logo";
import { displayHeader } from "../../Config/constant";
import AuthContext from "../../Context/AuthContext";
import theme from "../../Theme/theme";
import CustomButtonDashboard from "./Common/CustomButtonDashboard";
import { useStyle } from "./style";
import useDashboard from "./useDashboard";

const Dashboard: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const { tableMenuLeft, navigate } = useDashboard();
  const { pathname }: any = useLocation();
  const { type } = useParams();
  const style = useStyle();

  return (
    <div className={style.container}>
      <div
        style={{
          width: "218px",
          minWidth: "218px",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Logo />

            <div className={style.containerButon}>
              {tableMenuLeft.map((item) => {
                return (
                  <CustomButtonDashboard
                    key={item.name}
                    icon={item.icon}
                    name={item.name}
                    onClick={item.onClick}
                    background={item.path === pathname ? "#3A3A3A" : undefined}
                    child={item.child}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <Button
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => navigate("profil")}
            >
              <img
                src={image}
                alt="profil"
                style={{ width: "100px", height: "93px" }}
              />
              <Typography style={{ textTransform: "none" }}>
                Mon Profil
              </Typography>
            </Button>
            <Button
              onClick={logout}
              color="inherit"
              style={{ textTransform: "none" }}
            >
              Déconnexion
            </Button>
          </div>
        </div>
      </div>
      <div
        className={style.rootRight}
        style={{
          background:
            type === "2"
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
        }}
      >
        {displayHeader(pathname)}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
