/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from "react";

import {
  ApolloCache,
  FetchResult,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

// import * as yup from "yup";
import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import Loading from "../../Components/Loading/Loading";
import {
  RulesPlanification,
  initRulesPlanification,
  validationSchema,
} from "../../Config/constant";
import {
  Commande,
  Maybe,
  Mutation,
  MutationUpdateRenouvellementArgs,
  Query,
  QueryGetListCommandesArgs,
} from "../../graphql/__generated__/types";
import { UPDATE_RENEWAL_ORDER } from "../../graphql/command/mutation";
import { GET_ALL_ORDERS } from "../../graphql/command/query";
// eslint-disable-next-line import/no-cycle
import CustomPlanner from "./CustomPlanification/CustomPlanner";
import { stylesX, useStyles } from "./style";

const Planifications: React.FC = () => {
  const { activ, id } = useParams();
  const navigate = useNavigate();
  const textTitle = !id
    ? "Planifications"
    : `Modification Plannification : n°${id}`;
  const [planification, setPlanification] = useState<RulesPlanification>({
    rules: initRulesPlanification.rules,
  });
  const [open, setopen] = React.useState<boolean>(false);
  const [message, setmessage] = React.useState<string>("");
  const style = useStyles();

  const [getRenouvellementById, { loading }] = useLazyQuery<
    Query,
    QueryGetListCommandesArgs
  >(GET_ALL_ORDERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (res: Query) => {
      if (res?.GetListCommandes && res?.GetListCommandes?.length > 0) {
        setPlanification({
          rules: JSON.parse(res.GetListCommandes[0]?.renouvellement?.rrule),
        });
      }
    },
  });

  const [UpdateOrder, { loading: loadUpdateOrder }] = useMutation<
    Mutation,
    MutationUpdateRenouvellementArgs
  >(UPDATE_RENEWAL_ORDER);

  const ShowMessageError = (text: string) => {
    setopen(true);
    setmessage(text);
  };

  const onConfirmUpdate = () => {
    UpdateOrder({
      variables: {
        input: {
          id: Number(id),
          renouvellement: JSON.stringify(planification.rules),
        },
      },
      update: (
        cache: ApolloCache<any>,
        result: Omit<FetchResult<Mutation>, "context">,
      ) => {
        const resultat = result.data?.UpdateRenouvellement;
        const allQuery = cache.readQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ALL_ORDERS,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: 1,
            },
          },
        });

        const dataUpdated = allQuery?.GetListCommandes?.map(
          (i: Maybe<Commande>) => {
            return {
              ...i,
              renouvellement: i?.id === id ? resultat : i?.renouvellement,
            };
          },
        );
        if (dataUpdated) {
          cache.writeQuery<Query, QueryGetListCommandesArgs>({
            query: GET_ALL_ORDERS,
            variables: {
              input: {
                take: 50,
                skip: 0,
                TypeCommandes: 1,
              },
            },
            data: {
              GetListCommandes: dataUpdated as any,
            },
          });
        }
        navigate(-1);
      },
    });
  };

  const onValidate = () => {
    validationSchema
      .validate({
        repeatNumber: Number(planification.rules.repeatNumber),
        frequency: planification.rules.repeatType,
      })
      // eslint-disable-next-line consistent-return
      .then(() => {
        const { endType, endValue, repeatType, selectedDays } =
          planification.rules;
        if (repeatType === "week" && selectedDays.length === 0) {
          return ShowMessageError(
            "Veuillez sélectionner au moins un jour de repetition",
          );
        }
        if (endType) {
          if (endType === "at" && !endValue) {
            return ShowMessageError("Veuillez ecrire la date de fin");
          }
          if (endType === "after" && !endValue) {
            return ShowMessageError("Veuillez ecrire le nombre d'occurence");
          }

          return onConfirmUpdate();
        }
        return ShowMessageError("Veuillez sélectionner se termine");
      })
      .catch((error) => ShowMessageError(error.message));
  };

  React.useEffect(() => {
    if (activ && id) {
      getRenouvellementById({
        variables: {
          input: {
            skip: 0,
            take: 1,
            TypeCommandes: Number(activ),
            search: id,
          },
        },
      });
    }
  }, [activ, id, getRenouvellementById]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <Loading loading={loadUpdateOrder} />
        <CustomSnackBar
          message={message}
          open={open}
          setOpen={setopen}
          severity="error"
        />
        <Typography sx={stylesX.textPlan}>Planification</Typography>
        <Typography style={{ color: "#909090" }}>
          Vous devez renseigner la(es) date(s) à la(aux)quelle(s) nous recevrons
          votre (vos) demande(s)
        </Typography>
      </div>
      {loading && (
        <>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </>
      )}
      {!loading && (
        <div className={style.containerBody}>
          <div>
            <Button sx={stylesX.buttonBack} onClick={() => navigate(-1)}>
              <NavigateBeforeIcon />
              Retour
            </Button>
          </div>
          <div className={style.containerTitle}>
            <div>
              <Typography style={stylesX.textTitle}>{textTitle}</Typography>
            </div>
            <div className={style.body}>
              <div style={{ width: "50%" }}>
                <CustomPlanner
                  planification={planification}
                  setPlanification={setPlanification}
                />
              </div>
              <div className={style.boxRight}>
                <div>
                  <Typography style={{ color: "#00000029" }}>
                    Récapitulatif des dates
                  </Typography>
                  {planification.rules?.dateRepeatList?.map((date) => {
                    return (
                      <Typography>
                        {moment(date).format("Do MMM YYYY")}
                      </Typography>
                    );
                  })}
                </div>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    color="error"
                    style={{ margin: "0 10px 0 0", textTransform: "unset" }}
                    onClick={() => navigate(-1)}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="success"
                    style={{ textTransform: "unset" }}
                    onClick={onValidate}
                  >
                    Confirmer
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Planifications;
