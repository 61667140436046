/* eslint-disable import/no-cycle */
import React, { useContext } from "react";

import { Typography } from "@mui/material";

import image from "../../../../Assets/img/profil.png";
import MortierIcon from "../../../../Assets/svg/Background/Mortier";
import AuthContext from "../../../../Context/AuthContext";
import CustomHeader from "../CustomHeader/CustomHeader";

const HeaderHome: React.FC = () => {
  const { Me } = useContext(AuthContext);

  return (
    <CustomHeader
      childLeft={
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={image} alt="profil" style={{ width: 81, height: 81 }} />
          <Typography
            style={{ fontSize: 24, fontWeight: "bold", color: "#ffffff" }}
          >
            Bonjour,
          </Typography>
          <p
            style={{
              fontSize: 24,
              textTransform: "capitalize",
              color: "#ffffff",
            }}
          >
            {Me && Me?.nom}
          </p>
        </div>
      }
      icon={
        <MortierIcon
          viewBox="100 20 80 130"
          style={{ height: "194px", width: "425px" }}
        />
      }
    />
  );
};

export default HeaderHome;
