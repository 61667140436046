/* eslint-disable import/no-cycle */
import React from "react";

import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import Adress from "../../../Components/Adress/Adress";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../../Components/Loading/Loading";
import ImageContext from "../../../Context/ImageContext";
import {
  Mutation,
  MutationCreateOrderArgs,
} from "../../../graphql/__generated__/types";
import { CREATE_ORDER } from "../../../graphql/command/mutation";
import InfoClient from "../InfoClient/InfoClient";
import InfoComplementaire from "../InfoComplementaire/InfoComplementaire";
import ModalConfirmation from "../common/ModalConfirmation";
import { stylesx, useStyle } from "./style";

export interface FormOrder {
  comment: string;
  doctorName: string;
  images?: string;
  orderFormule: string;
  patientName: string;
  pharmaceuticalForm: string;
  status?: string;
  NourissonsOrAnimals?: string;
  typePatient: string | undefined;
  validationContrat: boolean;
}

const AddCommand: React.FC = () => {
  const { imageurl: urlbase64 } = React.useContext(ImageContext);
  const { type: typeCommande } = useParams();
  const navigate = useNavigate();
  const style = useStyle();
  const [open, setOpen] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormOrder>();

  const openCloseModalConfirmation = () => setOpen((prev) => !prev);
  const [DoCreateOrder, { loading }] = useMutation<
    Mutation,
    MutationCreateOrderArgs
  >(CREATE_ORDER);

  const handleCreate = async (data: FormOrder) => {
    DoCreateOrder({
      variables: {
        input: {
          ...data,
          typePatient: data.typePatient || "",
          images: urlbase64 ? urlbase64?.toString() : undefined,
          typeCommande: Number(typeCommande || 3),
          renouvellement: null,
          // isRenouvellement
          //   ? JSON.stringify(planification)
          //   : null,
        },
      },
    });

    // if (isRenouvellement) {
    //   setopenModalRenouvellement((prev) => !prev);
    // }
  };

  const [openModalPlanification, setOpenModalPlanification] =
    React.useState(true);

  return (
    <div className={style.container}>
      <CustomModal
        open={openModalPlanification}
        setOpen={setOpenModalPlanification}
        title="Commande différée ou planifier un renouvellement"
        content={
          <div>
            <div>
              <Typography>Répéter tou(tes) les</Typography>
              <CustomTextInput color="secondary" style={{ width: "42px" }} />
            </div>
          </div>
        }
      />
      <CustomModal
        open={open}
        setOpen={setOpen}
        content={<ModalConfirmation />}
        confirmAction={{
          text: "Ok",
          style: {
            background: "#ffffff",
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
            color: "#000",
            fontWeight: "bold",
          },
          onClick: () => {
            openCloseModalConfirmation();
            navigate(-1);
          },
        }}
      />
      <Box sx={stylesx.root}>
        <Box sx={stylesx.container}>
          <Loading loading={loading} />
          <Adress />
          <Box sx={stylesx.boxColumn}>
            {urlbase64 && (
              <Box sx={stylesx.paddingBox}>
                <Typography variant="h6">Photo Ordonance</Typography>
                <img
                  src={urlbase64 as any}
                  style={{ ...stylesx.img, objectFit: "cover" }}
                  aria-hidden
                  alt="Picture of me taking a photo of an image"
                />
              </Box>
            )}
            <InfoClient control={control} errors={errors} />
            <InfoComplementaire
              control={control}
              errors={errors}
              isRequired={!urlbase64}
            />
          </Box>
          <Button
            style={{ textTransform: "unset", background: "#F2F2F2" }}
            color="inherit"
          >
            Commande différée ou planifier un renouvellement
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={stylesx.button}
            onClick={handleSubmit(handleCreate)}
          >
            Envoyer
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AddCommand;
