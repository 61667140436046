import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: InputCreateOrder!) {
    CreateOrder(input: $input) {
      id
      id_typecommande
      id_client
      datecommande
      dateheurecommande
      num_preparation
      data
      img
      renouvellement {
        id
        date
        rrule
        taskid
      }
      typecomande {
        id
        nom
      }
    }
  }
`;

export const UPDATE_RENEWAL_ORDER = gql`
  mutation UpdateRenouvellement($input: InputUpdateRenouvellement!) {
    UpdateRenouvellement(input: $input) {
      date
      id
      rrule
      taskid
    }
  }
`;

export const DELETE_RENEWAL_ORDER = gql`
  mutation DeleteRenouvellement($input: InputGetOrderById!) {
    DeleteRenouvellement(input: $input) {
      orderId
    }
  }
`;
