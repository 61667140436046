import React from "react";

import { Typography } from "@mui/material";
import Iframe from "react-iframe";

import { stylesx, useStyles } from "./style";

const Rupture: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const style = useStyles();
  return (
    <div className={style.container}>
      {loading && <Typography sx={stylesx.text}>Loading...</Typography>}
      <Iframe
        loading="lazy"
        onLoad={() => setLoading(false)}
        url="https://www.easypreprupture.fr/"
        id=""
        className={style.frame}
        display="block"
        position="relative"
      />
    </div>
  );
};

export default Rupture;
