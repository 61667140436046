import React from "react";

import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import CommandIconActif from "../../Assets/svg/menu/CommandActif";
import DevisIconActif from "../../Assets/svg/menu/DevisIcon";
import CardCommand from "../../Components/CardCommand/CardCommand";
import theme from "../../Theme/theme";
import {
  Query,
  QueryGetListCommandesArgs,
} from "../../graphql/__generated__/types";
import { GET_ALL_ORDERS } from "../../graphql/command/query";
import { stylesX, useStyle } from "./style";

const HomePage: React.FC = () => {
  const style = useStyle();
  const navigate = useNavigate();
  const { data } = useQuery<Query, QueryGetListCommandesArgs>(GET_ALL_ORDERS, {
    variables: {
      input: {
        take: 3,
        skip: 0,
        TypeCommandes: 4,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className={style.root}>
      <div className={style.boxChoiceButton}>
        <div style={{ padding: "0 45px 0px 55px" }}>
          <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
            Que souhaitez-vous faire ?
          </Typography>
          <div className={style.boxButton}>
            <Button
              className={style.buttonOrder}
              sx={{ background: theme.palette.primary.main }}
              onClick={() => navigate("3/order")}
              variant="contained"
            >
              <Typography
                sx={stylesX.textCommand}
                style={{ textTransform: "none" }}
              >
                Commander
              </Typography>
              <CommandIconActif
                viewBox="10 55 230 130"
                fill="#ffffff50"
                style={{
                  height: "138px",
                }}
              />
            </Button>

            <Button
              className={style.buttonDevis}
              onClick={() => navigate("2/devis")}
            >
              <DevisIconActif
                viewBox="10 40 280 160"
                fill={theme.palette.primary.dark}
                opacity={0.5}
                style={{
                  height: "138px",
                }}
              />
              <Typography
                sx={stylesX.textDevis}
                style={{ textTransform: "none" }}
              >
                Demander un devis
              </Typography>
            </Button>
          </div>
        </div>
        <div className={style.view}>
          <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
            Mes 03 dernières commandes
          </Typography>
          <div className={style.containerlistCard}>
            {data?.GetListCommandes?.map((command) => {
              const info = JSON.parse(command?.data);
              const price = info.price.find(
                (i: any) => i.label === "TTC conseillé",
              ).value;
              return (
                <CardCommand
                  style={{ width: "25%" }}
                  key={command?.id}
                  date={moment(command?.datecommande).format("DD/MM/YYYY")}
                  num={command?.id || 0}
                  price={price}
                  status={info.status}
                  namePatient={info.Nom_du_patient}
                  id={command?.id || 0}
                />
              );
            })}
          </div>
        </div>
        {/* <div className={style.helpBox}>
          <Button onClick={() => navigate("helpers")}>
            <p className={style.helptext}>Besoin d'aide ?</p>
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default HomePage;
