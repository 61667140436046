import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
    padding: "0 0 0 37px",
  },
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});
export const stylesX = {
  buttonBack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(34, 165, 91)",
    textTransform: "unset",
  },
};
