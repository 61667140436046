import { makeStyles } from "@mui/styles";

import theme from "../../Theme/theme";

export const useStyle = makeStyles({
  container: {
    padding: "10px 20px 15px 18px",
    height: "316px",
    width: "25%",
    background: "rgb(0 0 0 / 4%)",
    borderRadius: "17px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 10px 0 0",
    "@media(max-height:700px)": {
      height: "280px",
    },
  },
  rootDetailsPersonnal: { margin: "32px 0 0 0px" },
  rootPrice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rootFooter: {
    background: theme.palette.primary.main,
    width: "100%",
    padding: "10px 0 10px 0",
  },
});

export const stylesX = {
  colorTextNumber: { color: theme.palette.text.disabled },
  name: {
    color: theme.palette.primary.dark,
    fontSize: 18,
    fontWeight: "bold",
  },
  price: { color: theme.palette.primary.dark },
  colorStatus: { color: theme.palette.primary.contrastText },
  typeStatus: { color: theme.palette.primary.contrastText, fontWeight: "bold" },
};
