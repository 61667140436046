import * as React from "react";
import { SVGProps } from "react";

const SuiviIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 230 230"
    {...props}
  >
    <defs>
      <style>{".suiv{fill:#616161;}"}</style>
    </defs>
    <path
      className="suiv"
      d="m86.91,109.69c-6.15,0-13.95-4.28-17.43-6.39-3.65-2.22-15.64-10.1-16.74-18.52-1.66-12.72,1.64-28.01,9.06-41.95C76.14,15.9,106.8,4.07,119.21,10.61c9.27,4.89,14.94,11.04,17.33,18.8,4.38,14.24-3.52,30.94-11.98,46.83h0c-8.44,15.85-21.26,28.15-34.29,32.9-1.03.38-2.17.54-3.37.54h.01Zm23.71-93.45c-11.07,0-31.42,9.93-42.17,30.13-6.7,12.58-9.7,26.23-8.24,37.44.36,2.73,5,8.09,13.18,13.06,7.32,4.45,12.87,5.74,14.32,5.21,11.34-4.14,22.64-15.12,30.23-29.37h0c9.15-17.19,14.6-30.76,11.42-41.08-1.77-5.75-6.23-10.45-13.65-14.36-1.29-.68-3.03-1.03-5.09-1.03h0Z"
    />
    <path
      className="suiv"
      d="m54.37,157.09c-3.75,0-7.39-.84-10.73-2.61-7.09-3.77-11.68-11.02-12.93-20.42-1.19-8.93.81-18.83,5.62-27.88,3.29-6.18,6.46-9.25,10.26-9.96,4.91-.9,8.81,2.51,12.95,6.12,2.47,2.16,5.28,4.61,8.48,6.31,3.21,1.71,6.8,2.67,9.98,3.51,5.31,1.42,10.32,2.75,12.31,7.33,1.54,3.55.76,7.89-2.53,14.07h0c-7.77,14.6-21.19,23.52-33.42,23.52h.01Zm-6.22-53.48c-.07,0-.13,0-.19.02-.45.08-2.15.77-4.98,6.09-4.08,7.67-5.79,15.96-4.8,23.34.92,6.92,4.12,12.17,9.01,14.77,10.53,5.6,25.76-2.38,33.97-17.8h0c2.83-5.32,2.46-7.11,2.27-7.53-.54-1.24-4.16-2.21-7.35-3.06-3.56-.95-7.6-2.03-11.57-4.14s-7.12-4.86-9.9-7.29c-2.37-2.07-5.05-4.41-6.45-4.41h-.01Z"
    />
    <path
      className="suiv"
      d="m130.06,116.9c-9.55,14.92-13.35,31.03-11.33,43.38,1.24,7.54,25.61,21.48,34.52,17.89,10.79-4.34,21.93-13.84,30.36-27,15.38-24.03,11.09-54.18,1.07-60.65-24.29-15.69-39.24,2.34-54.62,26.37h0Z"
    />
    <path
      className="suiv"
      d="m98.67,175.97c-10.55,16.48-9.53,35.97,2.28,43.53s29.93.32,40.48-16.16c10.55-16.48-4.75-13.66-16.56-21.22s-15.65-22.64-26.2-6.15Z"
    />
  </svg>
);
export default SuiviIcon;
