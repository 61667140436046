import * as React from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

interface ICustomRadioButton {
  label: string;
  data?: { label: string; value: string }[];
  value?: any;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
}
const CustomRadioButton: React.FC<ICustomRadioButton> = (props) => {
  const { label, data, onChange, value } = props;

  return (
    <FormControl>
      <FormLabel
        id="demo-controlled-radio-buttons-group"
        style={{ color: "#000000", fontSize: 16 }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {data?.map((item) => {
          return (
            <FormControlLabel
              key={item.label}
              value={item.value}
              control={<Radio />}
              label={item.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioButton;
