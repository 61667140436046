import React from "react";

import { TextField, TextFieldProps } from "@mui/material";

interface ICustomTextInput {}
const CustomTextInput: React.FC<ICustomTextInput & TextFieldProps> = (
  props,
) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      margin="dense"
      size="small"
      {...props}
    />
  );
};

export default CustomTextInput;
