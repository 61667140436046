import * as React from "react";
import { SVGProps } from "react";

const DevisIconActif: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = "#616161" } = props;
  return (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 230 230"
      {...props}
    >
      <defs>
        <style>{`.devClass{fill:${fill};}`}</style>
      </defs>
      <path
        className="devClass"
        d="m152.44,214.76H48.54c-1.54,0-2.79-1.25-2.79-2.79V24.49c0-1.54,1.25-2.79,2.79-2.79h97.93c.77,0,1.51.32,2.04.89l24.97,26.88c.48.52.74,1.19.74,1.9v72.96c0,1.59,1.33,2.84,2.92,2.78.53-.02,1.05-.03,1.58-.03h.03c1.56,0,2.84-1.23,2.84-2.79V48.47c0-.7-.27-1.38-.74-1.9l-29.12-31.36c-.53-.57-1.27-.89-2.04-.89H41.17c-1.54,0-2.79,1.25-2.79,2.79v202.23c0,1.54,1.25,2.79,2.79,2.79h114.46c2.92,0,3.87-3.91,1.27-5.26-1.02-.53-2.03-1.1-3.01-1.7-.44-.27-.94-.42-1.45-.42h0Z"
      />
      <path
        className="devClass"
        d="m67.09,83.98h85.79c6.68,0,12.11-5.43,12.11-12.11s-5.43-12.11-12.11-12.11h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11Zm0-16.85h85.79c2.62,0,4.74,2.12,4.74,4.74s-2.12,4.74-4.74,4.74h-85.79c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74Z"
      />
      <path
        className="devClass"
        d="m180.86,222.13h.74v-.04c-.24,0-.49.03-.74.04Z"
      />
      <path
        className="devClass"
        d="m67.09,119.5h85.79c6.68,0,12.11-5.43,12.11-12.11s-5.43-12.11-12.11-12.11h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11Zm0-16.85h85.79c2.62,0,4.74,2.12,4.74,4.74s-2.12,4.74-4.74,4.74h-85.79c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74Z"
      />
      <path
        className="devClass"
        d="m67.09,155.02h68.31c1.17-2.59,2.57-5.05,4.17-7.37h-72.48c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74h81.11c2.94-2.46,6.18-4.58,9.66-6.28-1.52-.69-3.21-1.09-4.99-1.09h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11h.01Z"
      />
      <path
        className="devClass"
        d="m177.83,134.05c-21.86,0-39.58,17.72-39.58,39.58s17.72,39.58,39.58,39.58,39.58-17.72,39.58-39.58-17.72-39.58-39.58-39.58Zm5.85,30.28v5.99h-15.7c-.08.87-.08,1.82-.08,2.76s0,1.89.08,2.76h15.7v5.99h-14.67c1.74,5.91,5.52,9.07,11.04,9.07,5.05,0,8.28-2.6,10.65-7.89l7.89,4.81c-3.08,7.73-9.78,12.07-18.38,12.07-10.96,0-19.4-7.41-22.4-18.06h-6.47v-5.99h5.36c-.08-.87-.08-1.82-.08-2.76s0-1.82.08-2.76h-5.36v-5.99h6.47c3-10.57,11.44-18.06,22.4-18.06,8.6,0,15.3,4.34,18.38,12.07l-7.89,4.81c-2.37-5.28-5.6-7.89-10.65-7.89-5.52,0-9.31,3.24-11.04,9.07h14.67Z"
      />
    </svg>
  );
};
export default DevisIconActif;
