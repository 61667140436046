import React, { useContext } from "react";

import { useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CustomModal from "../../Components/CustomModal/CustomModal";
import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import AuthContext from "../../Context/AuthContext";
import {
  Mutation,
  MutationCreateContactArgs,
} from "../../graphql/__generated__/types";
import { CONTACT } from "../../graphql/contact/mutation";
import { useStyle } from "./style";

interface IFormContact {
  email: string;
  nature: string;
  contactNumber: string;
}

const defaultState: IFormContact = {
  email: "",
  nature: "",
  contactNumber: "",
};

const Contact: React.FC = () => {
  const { Me } = useContext(AuthContext);
  const style = useStyle();
  const info = [
    { label: "Adresse", value: Me?.client.adresse },
    { label: "Téléphone", value: Me?.client.liste_telephone_client[0]?.numero },
    { label: "Fax", value: Me?.client?.liste_telephone_client[1]?.numero },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormContact>({ defaultValues: defaultState });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [commentaire, setcommentaire] = React.useState("");

  const [DoCreateContact, { loading }] = useMutation<
    Mutation,
    MutationCreateContactArgs
  >(CONTACT, {
    onCompleted: (result) => {
      if (result.CreateContact?.message === "SUCCESS") {
        reset(defaultState);
        setOpen(true);
      }
    },
  });

  const onValideContact = (data: IFormContact) => {
    DoCreateContact({
      variables: {
        input: { ...data, commentaire },
      },
    });
  };

  return (
    <div className={style.container}>
      <Loading loading={loading} />
      <CustomModal
        open={open}
        setOpen={setOpen}
        title="Demande Envoyé"
        content={
          <Typography>
            Merci pour votre demande !{"\n"}Nous avons bien reçu votre message
            et nous vous recontacterons dans les plus brefs délais.
          </Typography>
        }
        confirmAction={{
          text: "Valider",
          onClick: () => {
            setOpen(false);
            navigate(-1);
          },
        }}
      />
      <div className={style.listInfo}>
        {info.map((i, index) => {
          return (
            <div
              style={{
                margin: index % 2 !== 0 ? "0px 15px 0 15px" : undefined,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
                {i.label}
              </Typography>
              <Typography style={{ fontSize: "14px", color: "#00000070" }}>
                {i.value}
              </Typography>
            </div>
          );
        })}
      </div>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: "700",
          marginLeft: "38px",
          marginTop: "10px",
        }}
      >
        Vous souhaitez nous contacter ou être recontacter
      </Typography>
      <div className={style.rootControleur}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              error={!!errors.email}
              helperText={errors?.email?.message}
              label="Email*"
              placeholder="Votre adresse mail *"
              InputProps={{ style: { height: 36, width: "25%" } }}
              value={value}
              onChange={onChange}
            />
          )}
          name="email"
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis.",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Ce champ doit être une adresse email valide",
            },
          }}
          defaultValue=""
        />
        <Controller
          name="contactNumber"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
            minLength: {
              value: 10,
              message: "ce champ doit etre contenir au moins 10 caracteres",
            },
          }}
          render={({ field }) => (
            <CustomTextInput
              error={!!errors.contactNumber?.message}
              id="contactNumber"
              label="Votre numero de téléphone *"
              InputProps={{ style: { height: 36, width: "25%" } }}
              type="number"
              {...field}
              helperText={errors.contactNumber?.message}
            />
          )}
        />
        <Controller
          name="nature"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
          }}
          render={({ field }) => (
            <CustomTextInput
              error={!!errors.nature?.message}
              id="nature"
              label="Nature*"
              InputProps={{ style: { height: 36, width: "25%" } }}
              type="text"
              {...field}
              helperText={errors.nature?.message}
            />
          )}
        />
      </div>
      <CustomTextInput
        placeholder="Votre Message"
        style={{
          background: "#F2F2F2",
          margin: "30px 34px 0 34px",
          width: "95%",
        }}
        multiline
        rows={8}
        value={commentaire}
        onChange={(e) => setcommentaire(e.target.value)}
      />
      <div className={style.boxButton}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            marginRight: "50px",
            marginTop: "20px",
            textTransform: "none",
          }}
          onClick={handleSubmit(onValideContact)}
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default Contact;
