import React from "react";

import { useLazyQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import HistoryCommandPrep from "../../../Assets/svg/menu/features/HistoryCommandPrep";
import HistoryCommandSended from "../../../Assets/svg/menu/features/HistoryCommandSended";
import HistoryDevisPrep from "../../../Assets/svg/menu/features/HistoryDevisPrep";
import HistoryPlanificationPrep from "../../../Assets/svg/menu/features/HistoryPlanificationPrep";
import {
  Commande,
  Maybe,
  Query,
  QueryGetListCommandesArgs,
} from "../../../graphql/__generated__/types";
import { GET_ALL_ORDERS } from "../../../graphql/command/query";

export const useFollowers = () => {
  const [data, setData] = React.useState<Maybe<Maybe<Commande>[]> | undefined>(
    [],
  );
  const [labelTitle, setlabel] = React.useState("");
  const { activ } = useParams();
  const navigate = useNavigate();

  const [getDevisById, { loading: loadingById }] = useLazyQuery<
    Query,
    QueryGetListCommandesArgs
  >(GET_ALL_ORDERS, {
    onCompleted: (result) => {
      setData(result.GetListCommandes);
    },
    fetchPolicy: "cache-first",
  });

  // const [Do_DELETE_ORDER, { loading }] = useMutation<
  //   Mutation,
  //   MutationDeleteRenouvellementArgs
  // >(DELETE_RENEWAL_ORDER);

  // const [UPDATE_ORDER, { loading: loadUpdateOrder }] = useMutation<
  //   Mutation,
  //   MutationUpdateRenouvellementArgs
  // >(UPDATE_RENEWAL_ORDER);
  const getOpacity = (value: string): number => (value === activ ? 1 : 0.5);
  const listButton = [
    {
      id: 3,
      label: "Devis et Commandes envoyés",
      icon: <HistoryCommandSended opacity={getOpacity("3")} />,
      onclick: () => navigate("3"),
    },
    {
      id: 2,
      label: "Historique des Devis",
      icon: <HistoryDevisPrep opacity={getOpacity("2")} />,
      onclick: () => navigate("2"),
    },
    {
      id: 4,
      label: "Historique des Commandes",
      icon: <HistoryCommandPrep opacity={getOpacity("4")} />,
      onclick: () => navigate("4"),
    },
    {
      id: 1,
      label: "Planification",
      icon: <HistoryPlanificationPrep opacity={getOpacity("1")} />,
      onclick: () => navigate("1"),
    },
  ];

  const handleSetLabel = (id: string): string => {
    switch (id) {
      case "1":
        return "Planification";
      case "2":
        return "Historique des Devis";
      case "3":
        return "Devis et Commandes envoyés";
      case "4":
        return "Historique des Commandes";
      default:
        return "";
    }
  };
  React.useEffect(() => {
    if (activ) {
      setlabel(handleSetLabel(activ));
    }
  }, [activ]);

  return {
    data,
    listButton,
    getDevisById,
    loading: loadingById,
    setData,
    activ,
    navigate,
    labelTitle,
  };
};
