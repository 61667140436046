import { makeStyles } from "@mui/styles";

import image from "../../Assets/img/background/background.jpg";

export const useStyles = makeStyles({
  constainer: {
    flex: 1,
    height: "100vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: 400,
    padding: "75px",
    borderRadius: "6px",
  },
  boxForgotPassword: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "4px",
  },

  textForgotPassword: { position: "absolute", bottom: "-5px", right: "0px" },
  btnConnected: {
    paddingX: "45px",
    borderRadius: 6,
    fontWeight: "800",
    textTransform: "unset",
  },
  btnClient: {
    paddingX: "35px",
    marginTop: "10px",
    textTransform: "unset",
    borderRadius: 3,
    fontWeight: "800",
  },
  boxHelperText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: 0,
    marginTop: "-8px",
  },
});
export const newStyles = {
  finalBox: {
    display: "flex",
    width: "100%",
    margin: "auto",
    backgroundColor: "gray",
    maxWidth: "unset",
    height: "100vh",
    backgroundImage: `url(${image})`,
    backgroundPosition: "center center",
    backgroundSize: "cover !important",
    justifyContent: "center",
  },
  boxLeft: {
    display: "flex",
    justifyContent: "center",
    width: {
      lg: "50%",
      md: "100%",
      xs: "100%",
    },
    borderRadius: "0px 33px 33px 0px",
    backgroundColor: "white",
  },
  boxRight: {
    width: "50%",
    display: {
      lg: "flex",
      md: "none",
      xs: "none",
    },
  },
  btnConnected: {
    paddingX: "40px",
    backgroundImage: `ùm$`,
    borderRadius: 3,
    fontWeight: "800",
    textTransform: "unset",
  },
  btnClient: {
    paddingX: "35px",
    marginY: "10px",
    textTransform: "unset",
    borderRadius: 3,
    fontWeight: "800",
  },
};
