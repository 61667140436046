import * as React from "react";
import { SVGProps } from "react";

const ProfilIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill } = props;
  return (
    <svg
      width={230}
      height={230}
      viewBox="0 0 230 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M182.083 28.75H47.9167C37.375 28.75 28.75 37.375 28.75 47.9167V182.083C28.75 192.625 37.375 201.25 47.9167 201.25H182.083C192.625 201.25 201.25 192.625 201.25 182.083V47.9167C201.25 37.375 192.625 28.75 182.083 28.75ZM115 57.5C133.496 57.5 148.542 72.5458 148.542 91.0417C148.542 109.537 133.496 124.583 115 124.583C96.5042 124.583 81.4583 109.537 81.4583 91.0417C81.4583 72.5458 96.5042 57.5 115 57.5ZM182.083 182.083H47.9167V179.879C47.9167 173.937 50.6 168.379 55.2 164.738C71.5875 151.608 92.3833 143.75 115 143.75C137.617 143.75 158.412 151.608 174.8 164.738C179.4 168.379 182.083 174.033 182.083 179.879V182.083Z"
        fill={fill}
      />
    </svg>
  );
};
export default ProfilIcon;
