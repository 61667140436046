import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    background: "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",
    flex: "1 1 0%",
    width: "100%",
    borderRadius: "42px 0px 0px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px -12px 6px",
    padding: "42px 0 16px",
    overflow: "auto",
  },
  header: { textAlign: "left", padding: "0 0 25px 25px" },
  containerBody: {
    flex: "1",
    display: "flex",
    height: "calc(100vh - 320px)",
    padding: "0 0 0 50px",
  },
  containerTitle: { flex: "1 1 0%", padding: "0px 0px 0px 50px" },
  root: {
    display: "flex",
    flexDirection: "column",
    // width: "50%",
    alignItems: "flex-start",
  },
  body: { display: "flex", flexDirection: "row", height: "100%" },
  bodyLeft: { display: "flex", alignItems: "center", width: "100%" },
  buttonMenu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "49px",
    width: "33px",
    height: "33px",
    fontSize: "14px",
    fontWeight: "700",
    margin: "5px 5px",
    border: "none",
    color: "#FFFFFF",
  },
  selectstartDate: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px 0",
    alignItems: "center",
  },
  boxEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxRight: {
    display: "flex",
    flex: "1 1 0%",
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "10px 0 0 10px",
  },
  datePicker: {
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
  },
});
export const stylesX = {
  repeat: {
    fontSize: "14px",
    textAlign: "start",
    width: "350px",
  },
  textPlan: { fontSize: "24px", color: "#616161", fontWeight: "600" },
  buttonBack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(34, 165, 91)",
    textTransform: "unset",
  },
  textTitle: {
    alignSelf: "flex-start",
    display: "flex",
    fontSize: "25px",
    color: "#616161",
    fontWeight: "600",
  },
};
