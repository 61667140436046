import React from "react";

import { Typography } from "@mui/material";

import HelperSVG from "../../../../Assets/svg/HelpSVG";
import CustomHeader from "../CustomHeader/CustomHeader";

const HelpHeaders: React.FC = () => (
  <CustomHeader
    childLeft={
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ fontSize: 24, fontWeight: "bold", color: "#ffffff" }}
        >
          Aide
        </Typography>
      </div>
    }
    icon={
      <HelperSVG
        viewBox="0 0 20 15"
        fill="#ffffff50"
        style={{
          height: "172px",
          width: "425px",
        }}
      />
    }
  />
);

export default HelpHeaders;
