import { gql } from "@apollo/client";

export const DELETE_ME = gql`
  mutation DeleteMe {
    DeleteMe {
      id
    }
  }
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($input: InputUpdateMe!) {
    UpdateMe(input: $input) {
      id
    }
  }
`;
