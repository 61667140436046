import * as React from "react";
import { SVGProps } from "react";

const DevisIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 230 230"
    {...props}
  >
    <defs>
      <style>{".dev{fill:#616161;}"}</style>
    </defs>
    <circle className="dev" cx={178.11} cy={174.21} r={39.58} />
    <path
      className="dev"
      d="m152.72,215.33H48.82c-1.54,0-2.79-1.25-2.79-2.79V25.06c0-1.54,1.25-2.79,2.79-2.79h97.93c.77,0,1.51.32,2.04.89l24.97,26.88c.48.52.74,1.19.74,1.9v72.96c0,1.59,1.33,2.84,2.92,2.78.53-.02,1.05-.03,1.58-.03h.03c1.56,0,2.84-1.23,2.84-2.79V49.04c0-.7-.27-1.38-.74-1.9l-29.12-31.36c-.53-.57-1.27-.89-2.04-.89H41.45c-1.54,0-2.79,1.25-2.79,2.79v202.23c0,1.54,1.25,2.79,2.79,2.79h114.46c2.92,0,3.87-3.91,1.27-5.26-1.02-.53-2.03-1.1-3.01-1.7-.44-.27-.94-.42-1.45-.42h0Z"
    />
    <path
      className="dev"
      d="m67.37,84.55h85.79c6.68,0,12.11-5.43,12.11-12.11s-5.43-12.11-12.11-12.11h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11Zm0-16.85h85.79c2.62,0,4.74,2.12,4.74,4.74s-2.12,4.74-4.74,4.74h-85.79c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74Z"
    />
    <path className="dev" d="m181.14,222.7h.74v-.04c-.24,0-.49.03-.74.04Z" />
    <path
      className="dev"
      d="m67.37,120.07h85.79c6.68,0,12.11-5.43,12.11-12.11s-5.43-12.11-12.11-12.11h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11Zm0-16.85h85.79c2.62,0,4.74,2.12,4.74,4.74s-2.12,4.74-4.74,4.74h-85.79c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74Z"
    />
    <path
      className="dev"
      d="m67.37,155.59h68.31c1.17-2.59,2.57-5.05,4.17-7.37h-72.48c-2.62,0-4.74-2.12-4.74-4.74s2.12-4.74,4.74-4.74h81.11c2.94-2.46,6.18-4.58,9.66-6.28-1.52-.69-3.21-1.09-4.99-1.09h-85.79c-6.68,0-12.11,5.43-12.11,12.11s5.43,12.11,12.11,12.11h.01Z"
    />
  </svg>
);
export default DevisIcon;
