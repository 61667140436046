import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: "1 1 0%",
    background: "white",
    width: "100%",
    borderTopLeftRadius: "26px",
    boxShadow: "0px -12px 6px #00000029",
  },
  frame: {
    position: "relative",
    display: "flex",
    flex: "1 1 0%",
    width: "100%",
    border: "0px",
    borderTopLeftRadius: "26px",
  },
});
export const stylesx = {
  text: {
    position: "absolute",
    left: "60%",
    top: "50%",
    fontSize: "20px",
    opacity: 0.2,
  },
};
