import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  boxText: { padding: "10px" },
  name: {
    fontSize: "8px !important",
    lineHeight: "10px !important",
    textAlign: "left",
    fontWeight: "bold !important",
  },
  address: {
    fontSize: "6px !important",
    textAlign: "left",
    lineHeight: "10px !important",
  },
  icon: {
    backgroundColor: "gray",
    padding: "7px",
    color: "#fff",
    borderRadius: "6px",
  },
});

export default useStyles;
