import React from "react";

import VerifiedIcon from "@mui/icons-material/Verified";
import { Button, Typography } from "@mui/material";

interface IContentConfirmDialog {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const ContentConfirmDialog: React.FC<IContentConfirmDialog> = ({ onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <VerifiedIcon fontSize="large" />
      <Typography>Merci de nous avoir contacté!</Typography>
      <Typography>Nous allons revenir vers vous rapidement</Typography>
      <Button onClick={onClick}>Fermer</Button>
    </div>
  );
};

export default ContentConfirmDialog;
