import moment from "moment";
import * as yup from "yup";

import HeaderCommand from "../Container/Dashboard/Header/Command/HeaderCommand";
import HeaderContact from "../Container/Dashboard/Header/Contact/HeaderContact";
import HeaderDevis from "../Container/Dashboard/Header/Devis/HeaderDevis";
import HeaderFactures from "../Container/Dashboard/Header/Factures/HeaderFactures";
import HelpHeaders from "../Container/Dashboard/Header/Help/HelpHeaders";
// eslint-disable-next-line import/no-cycle
import HeaderHome from "../Container/Dashboard/Header/Home/HeaderHome";
import HeaderProfil from "../Container/Dashboard/Header/Profil/HeaderProfil";
import HeaderRupture from "../Container/Dashboard/Header/Ruptures/HeaderRupture";
import HeaderSuivi from "../Container/Dashboard/Header/Suivi/HeaderSuivi";

export const API = process.env.REACT_APP_API_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGO = process.env.REACT_APP_URL_LOGO;
export const COLOR_PRIMARY = {
  MAIN: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
  DARK: process.env.REACT_APP_COLOR_PRIMARY_DARK,
};

export const TYPE_COMMAND = {
  COMMAND: "COMMAND",
  DEVIS: "DEVIS",
};

export const SCREEN = {
  COMMAND: "Commandes",
  DEVIS: "Devis",
};

export const TOKEN = "token";
export const Me = "Me";

export const TypePatient = [
  { name: "Nourrisson - < 30 mois", id: "4" },
  { name: "Pédiatrie - 0 à 15 ans", id: "1" },
  { name: "Adulte", id: "2" },
  { name: "Vétérinaire", id: "0" },
];

export const getColor = (index: number): string => {
  if (index % 2 === 0) {
    return "#F2F2F2";
  }
  return "#FFFFFF";
};

export const dataSelect = [
  "Vous avez constaté une erreur sur votre commande",
  "Vous avez besoin d’un contrat de sous-traitance",
  "Vous avez besoin de l’annexe technique",
  "Nous n’avons pas répondu à votre question",
].map((item) => ({ label: item, value: item }));

export const getColorMenu = (path: string, pathname: string) => {
  switch (pathname) {
    case path:
      return "#02a358";
    default: {
      if (path.startsWith("/dashboard/suivi")) {
        return "#02a358";
      }
      return undefined;
    }
  }
};
export const displayHeader = (path?: string) => {
  if (path?.startsWith("/dashboard/suivi")) {
    return <HeaderSuivi />;
  }
  switch (path) {
    case "/dashboard":
      return <HeaderHome />;
    case "/dashboard/3/order":
      return <HeaderCommand />;
    case "/dashboard/3/order/add":
      return <HeaderCommand />;
    case "/dashboard/2/devis":
      return <HeaderDevis />;
    case "/dashboard/2/devis/add":
      return <HeaderDevis />;
    case "/dashboard/suivi":
      return <HeaderSuivi />;
    case "/dashboard/fact":
      return <HeaderFactures />;
    case "/dashboard/contact":
      return <HeaderContact />;
    case "/dashboard/controle":
      return <HeaderRupture />;
    case "/dashboard/profil":
      return <HeaderProfil />;
    case "/dashboard/profil/edit":
      return <HeaderProfil />;
    case "/dashboard/helpers":
      return <HelpHeaders />;
    default:
      return null;
      break;
  }
};

export const SetTitleByPathName = (pathName: string): string => {
  switch (pathName) {
    case "1":
      return "Plannification";
    case "2":
      return "Historique des Devis";
    case "3":
      return "Devis et commandes envoyés";
    case "4":
      return "Historique des Commandes";
    default:
      return "";
  }
};

export const validationSchema = yup.object().shape({
  repeatNumber: yup
    .number()
    .positive("Le nombre de fois à répéter ne peux pas etre moin de 1 fois.")
    .integer()
    .required("Veuillez entrer au moins 1 sur la repetitions"),
  frequency: yup.string().required('Veuillez choisir "Semaine ou Mois"'),
});

export interface RulesPlanification {
  rules: {
    selectedDays: string[];
    repeatNumber: number;
    endType: "after" | "at" | undefined;
    endValue: Date | number | undefined;
    repeatType: "week" | "month" | undefined;
    startValue: string | Date | undefined;
    dateRepeatList?: string[];
  };
}
export const initRulesPlanification: RulesPlanification = {
  rules: {
    repeatNumber: 1,
    repeatType: undefined,
    startValue: moment(new Date()).format("DD-MM-YYYY"),
    endType: undefined,
    endValue: undefined,
    selectedDays: [],
  },
};

export const checkTypeFile = (chaineBase64: string | null) => {
  // Vérifier l'en-tête de la chaîne base64 pour le type de fichier
  if (chaineBase64 && chaineBase64.startsWith("data:application/pdf;base64,")) {
    return true;
  }
  return false;
};
