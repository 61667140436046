import theme from "../../../Theme/theme";

export const stylesx = {
  root: {
    flex: 1,
    backgroundColor: theme.palette.primary.light,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: "auto",
    height: "calc(100vh - 300px)",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px",
    "@media (max-width: 508px)": {
      display: "none",
    },
  },
  containerMobile: {
    display: "none",
    "@media (max-width: 508px)": {
      display: "block",
    },
  },
  firstColumn: { display: "flex", alignItems: "flex-start" },
  buttonColumn: { display: "flex", alignItems: "center" },
};
