import * as React from "react";

import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
// import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import theme from "../../../Theme/theme";

interface ICustomSelect {
  label: string;
  value?: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  data: {
    label: string;
    value: string;
  }[];
  errors: boolean;
  errorLabel?: string;
}
const CustomSelect: React.FC<ICustomSelect> = (props) => {
  const { label, value, data, setValue, errors, errorLabel } = props;

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <div style={{ width: "100%", marginTop: "8px" }}>
      <FormControl sx={{ m: 0, minWidth: "100%" }} size="small" error={errors}>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={value}
          label={label}
          onChange={handleChange}
          defaultValue={value}
        >
          {data.map((item) => (
            <MenuItem value={item.value} key={item.label}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: theme.palette.error.main }}>
          {errors && errorLabel && errorLabel}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
