import * as React from "react";
import { SVGProps } from "react";

const CommandIconActif: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = "#616161" } = props;
  return (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 230 230"
      {...props}
    >
      <defs>
        <style>{`.fillComm{fill:${fill};}`}</style>
      </defs>
      <path
        className="fillComm"
        d="m218.17,42.46c2.18,0,3.95-1.77,3.95-3.95s-1.77-3.95-3.95-3.95h-24.8c-1.88,0-3.5,1.32-3.87,3.16l-8.77,43.06H16.77c-1.31,0-2.54.66-3.26,1.75-.72,1.09-.85,2.47-.34,3.68l32.5,77.05c.61,1.45,2.03,2.39,3.6,2.39h114.17l-1.52,7.45H49.7c-2.18,0-3.95,1.77-3.95,3.95s1.77,3.95,3.95,3.95h13.94c-5.29,3.43-8.81,9.37-8.81,16.15,0,10.63,8.61,19.24,19.24,19.24s19.24-8.61,19.24-19.24c0-6.78-3.51-12.72-8.81-16.15h49.9c-4.94,3.48-8.17,9.22-8.17,15.72,0,10.63,8.61,19.24,19.24,19.24s19.24-8.61,19.24-19.24c0-6.5-3.23-12.24-8.17-15.72h8.6c1.88,0,3.5-1.32,3.87-3.16l27.58-135.38h21.57ZM33.47,114.24h42.66v18.62h-34.8c-3.62-8.59-4.19-9.94-7.85-18.62Zm50.56,0h43.99v18.62h-43.99v-18.62Zm0-7.9v-17.74h43.99v17.74h-43.99Zm43.99,34.42v17.07h-43.99v-17.07h43.99Zm7.9,0h32.54l-3.51,17.07h-29.03v-17.07Zm0-7.9v-18.62h37.98l-3.82,18.62h-34.16Zm39.6-26.52h-39.6v-17.74h43.21l-3.61,17.74Zm-99.39-17.74v17.74H30.14c-2.85-6.76-7.48-17.74-7.48-17.74h53.47Zm-31.47,52.16h31.47v17.07h-24.27s-4.52-10.73-7.2-17.07Z"
      />
      <path
        className="fillComm"
        d="m211.96,117.56c0-1.8-1.46-3.26-3.26-3.26h-18.75c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26h18.75c1.8,0,3.26-1.46,3.26-3.26Z"
      />
      <path
        className="fillComm"
        d="m225.95,132.48h-18.75c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26h18.75c1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26Z"
      />
      <path
        className="fillComm"
        d="m216.31,150.65h-18.75c-1.8,0-3.26,1.46-3.26,3.26s1.46,3.26,3.26,3.26h18.75c1.8,0,3.26-1.46,3.26-3.26s-1.46-3.26-3.26-3.26Z"
      />
    </svg>
  );
};
export default CommandIconActif;
