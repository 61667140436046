import React from "react";

import { Typography } from "@mui/material";

import SuiviIconActif from "../../../../Assets/svg/menu/SuiviActif";
import CustomHeader from "../CustomHeader/CustomHeader";

const HeaderSuivi: React.FC = () => (
  <CustomHeader
    childLeft={
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ fontSize: 24, fontWeight: "bold", color: "#ffffff" }}
        >
          Suivi
        </Typography>
      </div>
    }
    icon={
      <SuiviIconActif
        viewBox="100 0 80 130"
        fill="#ffffff50"
        style={{
          height: "138px",
          width: "425px",
        }}
      />
    }
  />
);

export default HeaderSuivi;
