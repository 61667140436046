import React from "react";

import { Typography } from "@mui/material";

import Sponsor from "../../../Assets/img/sponsor.png";

interface IModalConfirmations {
  type?: string;
}

const ModalConfirmation: React.FC<IModalConfirmations> = (props) => {
  const { type } = props;
  return (
    <div style={{ width: 250 }}>
      <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
        La {type === "3" ? "commande" : "devis"} a bien été envoyée
      </Typography>
      <Typography style={{ padding: "15px 0px", fontWeight: "500" }}>
        Tout envoi d’ordonnance est sécurisé, les données sont cryptées et les
        images sur un serveur agréé Hébergeur de Données de Santé
      </Typography>
      <img
        src={Sponsor}
        alt="logosponsor"
        style={{ width: "224px", objectFit: "contain" }}
      />
    </div>
  );
};

export default ModalConfirmation;
