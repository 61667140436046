import React from "react";

import { Typography } from "@mui/material";

import { styleCustomField } from "./style";

interface ICustomField {
  label: string;
  value: string;
}
const CustomField: React.FC<ICustomField> = ({ label, value }) => {
  const style = styleCustomField();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography className={style.text}>{label}</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {value?.split("**").map((text, index) => {
          return (
            <Typography
              key={`${index.toString()}s`}
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "11px",
                textAlign: "start",
                textTransform: "unset",
              }}
            >
              {text}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default CustomField;
