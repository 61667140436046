import { gql } from "@apollo/client";

export const GET_ALL_FACTURES = gql`
  query GetListFactures {
    GetListFactures {
      id
      date_facture
      nom_facture
      montant
      id_client
      url
      client {
        id
        id_identite
      }
    }
  }
`;

export const GET_FACTURES_BY_ID = gql`
  query GetFactureById($id: Int!) {
    GetFactureById(id: $id) {
      id
      date_facture
      nom_facture
      montant
      id_client
      url
      client {
        identite {
          mail
        }
      }
    }
  }
`;
