/* eslint-disable no-restricted-globals */
import React from "react";

import { Box, Button, Divider, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";

import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
// import Historique from "../Historique/Devis/DevisHistorique";
import { stylesX } from "./style";
import { useFollowers } from "./utils/utils";

interface FormValues {
  search: string;
}

const Suivi: React.FC = () => {
  const { control, handleSubmit } = useForm<FormValues>();
  const { listButton, activ, navigate, labelTitle } = useFollowers();
  const handleSearch = (result: FormValues) => {
    navigate(`${activ}/${result.search}`);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSubmit(handleSearch)();
    }
  };

  React.useEffect(() => {
    if (
      !activ ||
      Number(activ) > 4 ||
      isNaN(Number(activ)) ||
      Number(activ) <= 0
    ) {
      navigate("3");
    }
  }, [activ, navigate]);

  return (
    <Box sx={stylesX.container}>
      <Box sx={stylesX.root}>
        <Typography sx={stylesX.title}>{labelTitle}</Typography>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              id="Recherche"
              label="Recherche"
              fullWidth={false}
              style={{
                width: "320px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "17px",
                opacity: "1",
              }}
              onChange={onChange}
              value={value}
              onKeyDown={onKeyDown}
              InputProps={{ style: { borderRadius: 16 } }}
            />
          )}
          name="search"
          defaultValue=""
        />
      </Box>
      <Divider style={{ margin: "10px 0 10px 0" }} />
      <div
        style={{ display: "flex", padding: "16px 0 0 0", flexDirection: "row" }}
      >
        <div
          style={{
            margin: "0px 38px 0px 0px",
            height: "calc(100vh - 284px)",
            overflow: "auto",
          }}
        >
          {listButton.map((item) => {
            return (
              <Button
                key={item.id.toString()}
                style={{
                  marginBottom: "0px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor:
                    activ === item.id.toString()
                      ? "rgb(0 0 0 / 3%)"
                      : undefined,
                  borderRadius: "16px",
                  padding: "10px",
                }}
                onClick={item.onclick}
              >
                {item.icon}
              </Button>
            );
          })}
        </div>
        <div style={{ display: "flex", flex: "1" }}>
          <Outlet />
        </div>
      </div>
    </Box>
  );
};

export default Suivi;
