import React, { useContext } from "react";

import { useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import image from "../../Assets/img/profil.png";
import CustomModal from "../../Components/CustomModal/CustomModal";
import Loading from "../../Components/Loading/Loading";
import AuthContext from "../../Context/AuthContext";
import theme from "../../Theme/theme";
import { Mutation } from "../../graphql/__generated__/types";
import { DELETE_ME } from "../../graphql/user/mutation";
import CustomField from "./common/CustomField";
import { useStyle } from "./style";

const Profil: React.FC = () => {
  const { Me } = useContext(AuthContext);
  const navigate = useNavigate();
  const style = useStyle();

  const [open, setOpen] = React.useState(false);
  const [openValidate, setOpenValidate] = React.useState(false);

  const handleOpenModal = () => setOpen((prev) => !prev);

  const [DeleteMyCompte, { loading: loadingDelete }] = useMutation<Mutation>(
    DELETE_ME,
    {
      onCompleted: (data) => {
        if (data.DeleteMe) {
          setOpenValidate(true);
        }
      },
    },
  );

  return (
    <div className={style.container}>
      <Loading loading={loadingDelete} />
      <CustomModal
        open={openValidate}
        setOpen={setOpenValidate}
        title="Demande envoyé"
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Votre demande de suppression a bien été prise en compte.
            </Typography>
          </div>
        }
        cancelAction={{
          text: "Valider",
          onClick: () => {
            handleOpenModal();
            navigate(-1);
          },
        }}
      />
      <CustomModal
        title="Confirmation"
        open={open}
        setOpen={setOpen}
        content={
          <div>
            <Typography>
              Êtes vous sur de vouloir supprimer ce compte ?
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "end",
                padding: "10px 0 0 0",
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="inherit"
                onClick={handleOpenModal}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                size="large"
                color="error"
                style={{ marginLeft: 25 }}
                onClick={() => {
                  handleOpenModal();
                  DeleteMyCompte();
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        }
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "left",
          position: "relative",
          // width: "100%",
        }}
      >
        <img
          src={image}
          alt="profil"
          style={{
            width: "195px",
            height: "191px",
          }}
        />
        <Button
          style={{
            padding: "10px",
            position: "absolute",
            left: "123px",
            bottom: "47px",
          }}
          onClick={() => navigate("edit")}
        >
          <EditIcon
            fontSize="large"
            color="inherit"
            style={{
              color: "#ffffff",
              background: theme.palette.primary.main,
              width: "32px",
              height: "32px",
              borderRadius: "21px",
              padding: "5px",
            }}
          />
        </Button>
        <div>
          <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
            {Me?.nom}
          </Typography>
          <Typography style={{ fontWeight: "500" }}>
            {`${Me?.client?.ville}`}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // width: "100%",
        }}
      >
        <div style={{}}>
          <CustomField
            label="Tél"
            value={Me?.client?.liste_telephone_client[0]?.numero || ""}
          />
          <CustomField
            label="Fax"
            value={Me?.client?.liste_telephone_client[1]?.numero || ""}
          />
        </div>
        <div style={{ width: "50%" }}>
          <CustomField label="Mail" value={Me?.mail || ""} />
          <CustomField
            label="Adresse"
            value={
              `${Me?.client?.adresse} ,${Me?.client?.cp} ${Me?.client?.ville}` ||
              ""
            }
          />
        </div>
      </div>

      <Button
        color="error"
        onClick={handleOpenModal}
        style={{ textTransform: "unset", fontSize: "20px" }}
      >
        Supprimer le compte
      </Button>
    </div>
  );
};

export default Profil;
