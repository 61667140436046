/* eslint-disable import/no-cycle */
import React from "react";

import { Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import CustomRadioButton from "../../../Components/CustomRadio/CustomRadioButton";
import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
import { RulesPlanification } from "../../../Config/constant";
import theme from "../../../Theme/theme";
import CustomSelect from "../../Command/common/CustomSelect";
import { stylesX, useStyles } from "../style";

interface ICustomPlanner {
  planification: RulesPlanification;
  setPlanification: React.Dispatch<React.SetStateAction<RulesPlanification>>;
}
const CustomPlanner: React.FC<ICustomPlanner> = ({
  planification,
  setPlanification,
}) => {
  const style = useStyles();
  return (
    <div className={style.root}>
      <div className={style.bodyLeft}>
        <Typography sx={stylesX.repeat}>Répéter tou(te)s les</Typography>
        <CustomTextInput
          type="number"
          id="RepeatNumber"
          value={planification.rules?.repeatNumber?.toString() || ""}
          style={{ width: "120px", margin: "5px 10px 0 0" }}
          onChange={(e) => {
            const text = e.target.value;
            setPlanification((prev) => ({
              rules: {
                ...prev.rules,
                repeatNumber: Number(text),
              },
            }));
          }}
          error={planification.rules?.repeatNumber < 1}
        />
        <CustomSelect
          label=""
          data={[
            { label: "Semaine", value: "week" },
            { label: "Mois", value: "month" },
          ]}
          setValue={(selected) => {
            setPlanification((prev) => ({
              rules: {
                ...prev.rules,
                repeatType: selected as "week" | "month" | undefined,
              },
            }));
          }}
          value={planification.rules.repeatType}
          errors={false}
        />
      </div>
      <div style={{ width: "100%" }}>
        {planification.rules?.repeatType === "week" && (
          <>
            <Typography style={{ textAlign: "start", fontSize: "14px" }}>
              Répéter le
            </Typography>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {["L", "M", "ME", "J", "V", "S", "D"].map((days) => {
                return (
                  <button
                    type="button"
                    className={style.buttonMenu}
                    style={{
                      backgroundColor: planification.rules.selectedDays.find(
                        (i) => i === days,
                      )
                        ? theme.palette.primary.main
                        : "#D3D3D3",
                    }}
                    onClick={() => {
                      setPlanification((prev) => {
                        const startDaily = prev.rules.selectedDays || [];
                        return {
                          rules: {
                            ...prev.rules,
                            selectedDays: startDaily.includes(days)
                              ? startDaily.filter((i) => i !== days)
                              : [...startDaily, days],
                          },
                        };
                      });
                    }}
                  >
                    {days}
                  </button>
                );
              })}
            </div>
          </>
        )}
        <div>
          <div className={style.selectstartDate}>
            <Typography>Date de début</Typography>
            <DatePicker
              minDate={moment(new Date())}
              maxDate={
                planification.rules.endType === "at"
                  ? moment(
                      new Date(planification?.rules?.endValue || new Date()),
                    )
                  : undefined
              }
              format="DD-MM-YYYY"
              defaultValue={moment(new Date())}
              value={moment(
                new Date(planification?.rules?.startValue ?? new Date()),
              )}
              onChange={(newValue: any) => {
                setPlanification((prev) => ({
                  rules: {
                    ...prev.rules,
                    startValue: new Date(new Date(newValue).setHours(3)),
                  },
                }));
              }}
            />
          </div>
          <div className={style.boxEnd}>
            <CustomRadioButton
              label="Se termine"
              value={planification?.rules.endType}
              data={[
                { label: "Le", value: "at" },
                { label: "Après", value: "after" },
              ]}
              onChange={(e, value) =>
                setPlanification((prev) => ({
                  rules: {
                    ...prev.rules,
                    endType: value as "after" | "at" | undefined,
                    endValue: undefined,
                  },
                }))
              }
            />

            {planification.rules.endType === "at" && (
              <DatePicker
                format="DD-MM-YYYY"
                minDate={moment(new Date())}
                defaultValue={moment(new Date())}
                value={moment(planification.rules.endValue ?? new Date())}
                onChange={(date: any) => {
                  setPlanification((prev) => ({
                    rules: {
                      ...prev.rules,
                      endValue: new Date(date),
                    },
                  }));
                }}
                className={style.datePicker}
              />
            )}
            {planification.rules.endType === "after" && (
              <div>
                <Typography>Occurences</Typography>
                <CustomTextInput
                  id="occurence"
                  type="number"
                  style={{ width: "99px", margin: "5px 10px 0 0" }}
                  value={planification.rules.endValue}
                  onChange={(e) => {
                    const text = e.target.value;
                    setPlanification((prev) => ({
                      rules: {
                        ...prev.rules,
                        endValue: Number(text),
                      },
                    }));
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPlanner;
