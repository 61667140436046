import React, { useContext } from "react";

import { useMutation } from "@apollo/client";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import Logo from "../../Components/Logo/Logo";
import AuthContext from "../../Context/AuthContext";
import {
  Mutation,
  MutationLoginIdentiteArgs,
} from "../../graphql/__generated__/types";
import { LOGIN } from "../../graphql/login/mutation";
import { newStyles, useStyles } from "./style";

interface LoginForm {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const styles = useStyles();
  const navigate = useNavigate();
  const { setToken, token } = useContext(AuthContext);

  const { handleSubmit, control, reset } = useForm<LoginForm>();
  const [doLogin, { loading }] = useMutation<
    Mutation,
    MutationLoginIdentiteArgs
  >(LOGIN, {
    onCompleted: (data: Mutation) => {
      if (data.LoginIdentite && data.LoginIdentite.token) {
        setToken(data.LoginIdentite.token, data.LoginIdentite);
        reset({ email: "", password: "" });
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => event.preventDefault();

  const onSubmit = (data: LoginForm) => {
    doLogin({
      variables: {
        input: {
          email: data.email,
          password: data.password,
        },
      },
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") handleSubmit(onSubmit)();
  };

  if (token) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Box sx={newStyles.finalBox}>
      <Box sx={newStyles.boxLeft}>
        <Loading loading={loading} />
        <div className={styles.boxCenter}>
          <Logo />
          <Typography variant="h5" style={{ marginTop: 20 }}>
            Connexion
          </Typography>
          <Controller
            name="email"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Ce champ est requis",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Adresse email invalide",
              },
            }}
            render={({ field, fieldState }) => (
              <CustomTextInput
                id="email"
                label="E-mail"
                variant="outlined"
                sx={{ marginY: 3 }}
                onKeyDown={onKeyDown}
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: "Ce champ est requis" }}
            render={({ field, fieldState }) => (
              <CustomTextInput
                onKeyDown={onKeyDown}
                type={showPassword ? "text" : "password"}
                id="password"
                label="Mot de passe"
                sx={{ marginBottom: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                error={!!fieldState.error}
                helperText={
                  <div className={styles.boxHelperText}>
                    <Typography fontSize="10px">
                      {fieldState.error ? fieldState.error.message : null}
                    </Typography>
                    <Button
                      style={{
                        color: "#000",
                        textTransform: "none",
                        fontSize: "10px",
                      }}
                      onClick={() => navigate("forgotpass")}
                    >
                      Mot de passe oublié ?
                    </Button>
                  </div>
                }
              />
            )}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={newStyles.btnConnected}
            onClick={handleSubmit(onSubmit)}
          >
            Se connecter
          </Button>
          <Button
            variant="contained"
            color="inherit"
            size="large"
            sx={newStyles.btnClient}
            onClick={() => navigate("signup")}
          >
            Devenir client
          </Button>
        </div>
      </Box>
      <Box sx={newStyles.boxRight} />
    </Box>
  );
};

export default Login;
