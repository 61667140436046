import React from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { TOKEN } from "../../../Config/constant";

interface IProtectedRoute {
  token: string;
  redirectPath: any;
}
const ProtectedRoute: React.FC<IProtectedRoute> = ({
  token,
  redirectPath = "/",
}: any) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const isAuthenticated = localStorage.getItem(TOKEN) || token;

    if (!isAuthenticated) {
      navigate(redirectPath);
    }
  }, [redirectPath, navigate, token]);

  return <Outlet />;
};

export default ProtectedRoute;
