/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";

import { ApolloCache, FetchResult, useMutation } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { Controller, useForm } from "react-hook-form";
import Iframe from "react-iframe";
import { Link, useParams } from "react-router-dom";

import CustomModal from "../../../Components/CustomModal/CustomModal";
import CustomSnackBar from "../../../Components/CustomSnackBar/CustomSnackBar";
import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../../Components/Loading/Loading";
import {
  RulesPlanification,
  checkTypeFile,
  initRulesPlanification,
  validationSchema,
} from "../../../Config/constant";
import theme from "../../../Theme/theme";
import {
  Mutation,
  MutationCreateOrderArgs,
  Query,
  QueryGetListCommandesArgs,
} from "../../../graphql/__generated__/types";
import { CREATE_ORDER } from "../../../graphql/command/mutation";
import { GET_ALL_ORDERS } from "../../../graphql/command/query";
import CustomPlanner from "../../Planification/CustomPlanification/CustomPlanner";
import CustomSelect from "../common/CustomSelect";
import ModalConfirmation from "../common/ModalConfirmation";
import { useNewStyle } from "./NewStyle";

export interface FormOrder {
  comment: string;
  doctorName: string;
  images?: string;
  orderFormule: string;
  patientName: string;
  pharmaceuticalForm: string;
  status?: string;
  NourissonsOrAnimals?: string;
  typePatient: string | undefined;
  validationContrat: boolean;
}

const defaultValues: FormOrder = {
  comment: "",
  doctorName: "",
  orderFormule: "",
  patientName: "",
  pharmaceuticalForm: "",
  typePatient: "",
  validationContrat: false,
};
const NewAddCommand: React.FC = () => {
  const [file, setFile] = React.useState<string | ArrayBuffer | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [filename, setFilename] = React.useState("");

  const [planification, setPlanification] = React.useState<RulesPlanification>({
    rules: initRulesPlanification.rules,
  });

  const [openToast, setopenToast] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [openModal, setopenModal] = React.useState(false);

  const style = useNewStyle();
  const { type: typeCommande } = useParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    // getFieldState,
    watch,
    reset,
  } = useForm<FormOrder>({ defaultValues });

  const openCloseModalConfirmation = () => setOpen((prev) => !prev);

  const resetPlanification = () =>
    setPlanification({ rules: initRulesPlanification.rules });

  const [DoCreateOrder, { loading }] = useMutation<
    Mutation,
    MutationCreateOrderArgs
  >(CREATE_ORDER, {
    update: (
      cache: ApolloCache<any>,
      result: Omit<FetchResult<Mutation>, "context">,
    ) => {
      if (result.data?.CreateOrder?.id) {
        openCloseModalConfirmation();
        reset(defaultValues);
        resetPlanification();
        setFile(null);
        const resultat = {
          ...result.data?.CreateOrder,
          __typename: "Commande",
        };

        const allQuerySended = cache.readQuery<
          Query,
          QueryGetListCommandesArgs
        >({
          query: GET_ALL_ORDERS,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: 3,
            },
          },
        });

        const dataUpdated = [
          resultat,
          ...(allQuerySended?.GetListCommandes || []),
        ];

        cache.writeQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ALL_ORDERS,
          variables: {
            input: {
              take: allQuerySended?.GetListCommandes?.length || 0,
              skip: 0,
              TypeCommandes: 3,
            },
          },
          data: {
            GetListCommandes: dataUpdated as any,
          },
        });

        if (resultat?.renouvellement) {
          const allQueryRenouvellement = cache.readQuery<
            Query,
            QueryGetListCommandesArgs
          >({
            query: GET_ALL_ORDERS,
            variables: {
              input: {
                take: 50,
                skip: 0,
                TypeCommandes: 1,
              },
            },
          });
          const dataUpdatedRenouvellemnt = [
            resultat,
            ...(allQueryRenouvellement?.GetListCommandes || []),
          ];

          cache.writeQuery<Query, QueryGetListCommandesArgs>({
            query: GET_ALL_ORDERS,
            variables: {
              input: {
                take: allQueryRenouvellement?.GetListCommandes?.length || 0,
                skip: 0,
                TypeCommandes: 1,
              },
            },
            data: {
              GetListCommandes: dataUpdatedRenouvellemnt as any,
            },
          });
        }
      }
    },
  });
  const ShowMessageError = (text: string) => {
    setopenToast(true);
    setMessage(text);
  };

  const onValidate = () => {
    validationSchema
      .validate({
        repeatNumber: Number(planification.rules.repeatNumber),
        frequency: planification.rules.repeatType,
      })
      // eslint-disable-next-line consistent-return
      .then(() => {
        const { endType, endValue, repeatType, selectedDays } =
          planification.rules;
        if (repeatType === "week" && selectedDays.length === 0) {
          return ShowMessageError(
            "Veuillez sélectionner au moins un jour de repetition",
          );
        }
        if (endType) {
          if (endType === "at" && !endValue) {
            return ShowMessageError("Veuillez ecrire la date de fin");
          }
          if (endType === "after" && !endValue) {
            return ShowMessageError("Veuillez ecrire le nombre d'occurence");
          }

          return setopenModal((prev) => !prev);
        }
        return ShowMessageError("Veuillez sélectionner se termine");
      })
      .catch((error) => ShowMessageError(error.message));
  };

  const isRenouvellement =
    planification.rules.repeatNumber &&
    planification.rules.repeatType &&
    planification.rules.endValue;

  const handleCreate = async (data: FormOrder) => {
    DoCreateOrder({
      variables: {
        input: {
          ...data,
          typePatient: data.typePatient || "",
          images: file ? file?.toString() : undefined,
          typeCommande: Number(typeCommande || 3),
          renouvellement:
            typeCommande === "3" && isRenouvellement
              ? JSON.stringify(planification)
              : null,
        },
      },
    });
  };

  const handleChange = (f: any) => {
    setFilename(f.name);
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(f);
  };

  const fileTypes = ["JPG", "JPEG", "jpeg", "jpg", "PNG", "PDF", "pdf"];

  const TypePatient = [
    { label: "Nourrisson - < 30 mois", value: "4" },
    { label: "Pédiatrie - 0 à 15 ans", value: "1" },
    { label: "Adulte", value: "2" },
    { label: "Vétérinaire", value: "0" },
  ];

  React.useEffect(() => {
    reset(defaultValues);
    setFile(null);
  }, [reset, typeCommande]);

  return (
    <div className={style.container}>
      <div className={style.rootTopBox}>
        <Loading loading={loading} />
        <CustomSnackBar
          message={message}
          open={openToast}
          setOpen={setopenToast}
          severity="error"
        />
        <CustomModal
          open={open}
          setOpen={setOpen}
          content={<ModalConfirmation type={typeCommande} />}
          confirmAction={{
            text: "Ok",
            style: {
              background: "#ffffff",
              boxShadow:
                "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
              color: "#000",
              fontWeight: "bold",
            },
            onClick: () => {
              openCloseModalConfirmation();
            },
          }}
        />
        <CustomModal
          open={openModal}
          setOpen={setopenModal}
          title="Commande différée ou planifier un renouvellement"
          content={
            <div>
              <CustomPlanner
                planification={planification}
                setPlanification={setPlanification}
              />
              <Typography>
                Veuillez renseigner les dates auxquelles nous recevons vos
                demandes.
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  size="large"
                  color="error"
                  variant="contained"
                  style={{ textTransform: "unset" }}
                  onClick={() => {
                    resetPlanification();
                    setopenModal((prev) => !prev);
                  }}
                >
                  Annuler
                </Button>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 10, textTransform: "unset" }}
                  onClick={onValidate}
                >
                  Confirmer
                </Button>
              </div>
            </div>
          }
        />
        <div className={style.boxSelect}>
          <div className={style.rootSelect}>
            <Controller
              name="typePatient"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Ce champ est requis",
                },
              }}
              render={({ field: { value, onChange } }) => (
                <CustomSelect
                  label="Type de patient"
                  data={TypePatient}
                  setValue={onChange}
                  value={value}
                  errors
                  errorLabel={errors.typePatient?.message}
                />
              )}
            />
            {watch("typePatient") && watch("typePatient") !== "2" && (
              <Controller
                name="NourissonsOrAnimals"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomTextInput
                    id="adornment-weight"
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                    label={
                      watch("typePatient") === "4" ||
                      watch("typePatient") === "1"
                        ? "Poids"
                        : "Informations sur l'animal"
                    }
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </div>
          <Controller
            name="pharmaceuticalForm"
            control={control}
            rules={{
              required: {
                value: !file,
                message: "Ce champ est requis",
              },
            }}
            render={({ field: { onChange, value } }) => (
              <CustomTextInput
                error={!file}
                color="primary"
                id="galénique"
                label="Forme galénique"
                multiline
                rows={3}
                value={value}
                onChange={onChange}
                FormHelperTextProps={{
                  style: { background: "white", margin: "0" },
                }}
                helperText={!file ? errors.pharmaceuticalForm?.message : ""}
                style={{
                  width: "269px",
                  background: "#F9F9F9",
                  borderRadius: "6px",
                }}
              />
            )}
          />
        </div>
        <Controller
          name="orderFormule"
          control={control}
          rules={{
            required: {
              value: !file,
              message: "Ce champ est requis",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              error={!file}
              id="commande"
              value={value}
              label="Formule de la commande"
              rows={3}
              multiline
              onChange={onChange}
              helperText={!file ? errors.orderFormule?.message : ""}
              FormHelperTextProps={{
                style: { background: "white", margin: "0" },
              }}
              style={{
                width: "411px",
                background: "#F9F9F9",
                borderRadius: "6px",
              }}
            />
          )}
        />
      </div>
      <div className={style.boxCard}>
        <div className={style.rootCard}>
          <Typography style={{ fontWeight: "bold", margin: "0 0 20px 0" }}>
            Déposer votre ordonnance
          </Typography>

          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            // onTypeError={(err: any) => console.log(err)}
          >
            <div
              className={style.inputDrag}
              style={{
                background:
                  typeCommande === "2"
                    ? theme.palette.primary.main
                    : theme.palette.primary.dark,
              }}
            >
              <Typography
                style={{
                  fontSize: "25px",
                  width: "291px",
                  position: "absolute",
                  color: "#FFFFFF",
                }}
              >
                Glisser déposer vos documents ici
              </Typography>
              {file &&
                (checkTypeFile(file.toString() || null) ? (
                  <Iframe
                    loading="auto"
                    url={file.toString() || ""}
                    id=""
                    className={style.img}
                    display="block"
                    position="relative"
                  />
                ) : (
                  <img
                    src={file.toString()}
                    alt="order"
                    className={style.img}
                  />
                ))}
            </div>
          </FileUploader>
          {file && (
            <div className={style.boxSubtTileImg}>
              <Typography>{filename}</Typography>
              <Button
                size="large"
                style={{
                  width: "207px",
                  color: "red",
                  textTransform: "unset",
                }}
                onClick={() => {
                  setFilename("");
                  setFile(null);
                }}
              >
                Annuler la séléction
              </Button>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={{ fontWeight: "bold", margin: "0 0 12px 0" }}>
            Informations complémentaires
          </Typography>
          <Controller
            name="doctorName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomTextInput
                id="prescription"
                label="Nom du prescripteur"
                onChange={onChange}
                value={value}
                style={{
                  width: "100%",
                  border: "1px solid #FFE6E6",
                  borderRadius: "6px",
                  background: "#F9F9F9",
                }}
              />
            )}
          />
          <Controller
            name="patientName"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomTextInput
                id="patient"
                label="Nom du patient"
                onChange={onChange}
                value={value}
                style={{
                  width: "411px",
                  border: "1px solid #FFE6E6",
                  background: "#F9F9F9",
                  borderRadius: "6px",
                }}
              />
            )}
          />
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomTextInput
                id="commentaire"
                label="Commentaire"
                rows={3}
                onChange={onChange}
                multiline
                value={value}
                style={{
                  width: "411px",
                  border: "1px solid #FFE6E6",
                  background: "#F9F9F9",
                  borderRadius: "6px",
                }}
              />
            )}
          />
        </div>
      </div>
      <div>
        <div style={{ padding: "0px 0px 0px 30px" }}>
          <Controller
            name="validationContrat"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Ce champ est requis",
              },
            }}
            render={({ field: { value, ...field } }) => (
              <FormControlLabel
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "start",
                }}
                control={
                  <Checkbox
                    {...field}
                    checked={!!value}
                    style={{
                      color: errors.validationContrat?.message
                        ? "red"
                        : undefined,
                    }}
                  />
                }
                label={
                  <Typography
                    style={{
                      maxWidth: "100%",
                      textAlign: "start",
                      color: "#00000095",
                    }}
                  >
                    {`En cochant cette case, vous reconnaissez avoir décidé de la
                    sous-traitance de la préparation et avoir transmis les
                    informations que vous détenez dans l’intérêt du patient.
                    Vous déclarez également passer commande/devis en
                    connaissance de la réglementation et du cadre contractuel
                    qui vous lie à votre sous-traitant ( pour plus d’information `}
                    <Link
                      target="_blank"
                      to="https://ansm.sante.fr/documents/reference/bonnes-pratiques-de-preparation"
                    >
                      cliquez ici
                    </Link>
                    {` )`}
                  </Typography>
                }
              />
            )}
          />
        </div>
        <div style={{ height: "31px", margin: "5px 0 15px 0" }}>
          {typeCommande === "3" &&
            (isRenouvellement ? (
              <div className={style.boxSavegardPlanner}>
                <Typography
                  style={{ color: theme.palette.primary.main, fontSize: 16 }}
                >
                  Planification sauvegarde{" "}
                </Typography>
                <IconButton
                  aria-label="close"
                  color="secondary"
                  onClick={resetPlanification}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <Button
                style={{
                  textTransform: "unset",
                  color: "#000",
                  background: "#F9F9F9",
                }}
                onClick={() => setopenModal((prev) => !prev)}
                variant="contained"
                color="inherit"
              >
                Commande différée ou planifier un renouvellement
              </Button>
            ))}
        </div>
        <Button
          style={{
            width: 193,
            height: 41,
            backgroundImage: `ùm$`,
          }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleSubmit(handleCreate)}
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default NewAddCommand;
