import React, { useContext } from "react";

import { useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import image from "../../../Assets/img/profil.png";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import CustomTextInput from "../../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../../Components/Loading/Loading";
import AuthContext from "../../../Context/AuthContext";
import {
  Mutation,
  MutationUpdateMeArgs,
} from "../../../graphql/__generated__/types";
import { UPDATE_ME } from "../../../graphql/user/mutation";

interface IEditProfilForm {
  name: String;
  adress: String;
  tel: String;
  fax: String;
  mail: String;
  attachments: String;
}
const EditProfil: React.FC = () => {
  const { Me } = useContext(AuthContext);
  const [openValidation, setOpenValidation] = React.useState(false);

  const navigate = useNavigate();
  const [value, setvalue] = React.useState<IEditProfilForm>({
    adress: `${Me?.client?.adresse} ,${Me?.client?.cp} ${Me?.client?.ville}`,
    attachments: Me?.client.groupement || "",
    fax: Me?.client?.liste_telephone_client[1]?.numero || "",
    mail: Me?.mail || "",
    name: Me?.nom || "",
    tel: Me?.client?.liste_telephone_client[0]?.numero || "",
  });

  const [UpdateMe, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMeArgs
  >(UPDATE_ME, {
    onCompleted: (data) => {
      if (data.UpdateMe) {
        setOpenValidation((prev) => !prev);
      }
    },
  });
  const onConfirm = () => {
    UpdateMe({
      variables: {
        input: {
          fax: value.fax as string,
          mail: value.mail as string,
          nom: value.name as string,
          tel: value.tel as string,
        },
      },
    });
  };

  return (
    <div
      style={{
        background: "rgb(255, 255, 255)",
        flex: "1 1 0%",
        width: "100%",
        borderTopLeftRadius: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px -12px 6px",
        alignItems: "center",
      }}
    >
      <Loading loading={loadingUpdate} />
      <CustomModal
        open={openValidation}
        setOpen={setOpenValidation}
        title="Demande envoyé"
        content={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Votre demande de Modification a bien été prise en compte.
            </Typography>
          </div>
        }
        cancelAction={{
          text: "Valider",
          onClick: () => navigate(-1),
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <img
          src={image}
          alt="profil"
          style={{
            width: "217px",
            height: "227px",
          }}
        />
        <div>
          <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
            {Me?.nom}
          </Typography>
          <Typography style={{ fontWeight: "500" }}>
            {`${Me?.client?.adresse} ,${Me?.client?.cp} ${Me?.client?.ville}`}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1 1 0%",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <CustomTextInput
              id="tel"
              label={value.tel ? "" : "Tel"}
              fullWidth={false}
              value={value.tel}
              inputProps={{
                style: { background: "#F9F9F9" },
              }}
              onChange={(e) =>
                setvalue((prev) => ({ ...prev, tel: e.target.value }))
              }
            />
            <CustomTextInput
              id="Fax"
              label={value.fax ? "" : "Fax"}
              fullWidth={false}
              value={value.fax}
              inputProps={{
                style: { background: "#F9F9F9" },
              }}
              onChange={(e) =>
                setvalue((prev) => ({ ...prev, fax: e.target.value }))
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomTextInput
              variant="outlined"
              id="Mail"
              label={value.mail ? "" : "Mail"}
              fullWidth={false}
              inputProps={{
                style: { background: "#F9F9F9" },
              }}
              value={value.mail}
              onChange={(e) =>
                setvalue((prev) => ({ ...prev, mail: e.target.value }))
              }
            />
            {/* <CustomTextInput
              id="Attaché à"
              label={value.attachments ? "" : "Mail"}
              fullWidth={false}
              defaultValue={value.attachments}
              inputProps={{
                style: { background: "#F9F9F9" },
              }}
              value={value.attachments}
              onChange={(e) =>
                setvalue((prev) => ({ ...prev, attachments: e.target.value }))
              }
            /> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            size="large"
            variant="contained"
            style={{ margin: "10px 10px 10px 0" }}
            onClick={onConfirm}
          >
            Sauvegarder
          </Button>
          <Button
            size="large"
            variant="contained"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            Retour
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditProfil;
