import React, { ReactNode } from "react";

import { useStyle } from "../../style";

interface ICustomHeader {
  childLeft: ReactNode | JSX.Element;
  icon?: any;
}
const CustomHeader: React.FC<ICustomHeader> = (props) => {
  const { childLeft, icon } = props;
  const style = useStyle();

  return (
    <div className={style.boxTitle}>
      <div className={style.containerinformation}>{childLeft}</div>
      {icon && icon}
    </div>
  );
};

export default CustomHeader;
