import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation LoginIdentite($input: InputIdentiteUser!) {
    LoginIdentite(input: $input) {
      id
      nom
      typeidentite
      mail
      mdp
      token
      client {
        id
        groupement
        grossiste
        id_identite
        remise_generale
        adresse
        ville
        cp
        bloquer
        last_update
        liste_telephone_client {
          numero
          type_telephone
        }
      }
    }
  }
`;
