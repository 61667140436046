import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  container: {
    height: "calc(100vh - 138px)",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    background: "#FFFFFF",
    boxShadow: "0px -12px 6px #00000029",
    alignItems: "flex-start",
    borderRadius: "42px 0px 0px 0px",
    flexDirection: "column",
    overflowY: "auto",
  },
  listInfo: {
    width: "100%",
    margin: "0 0 0 34px",
    display: "flex",
    padding: "0 0",
    alignItems: "start",
    flexDirection: "row",
    justifyContent: "start",
    paddingTop: "25px",
  },
  rootControleur: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "14px 0 0 35px",
    width: "100%",
    "& .css-1z10yd4-MuiFormControl-root-MuiTextField-root": {
      marginTop: "5px",
    },
    "& .css-twdmtu": {
      marginTop: "5px",
    },
  },
  boxButton: {
    display: "flex",
    justifyContent: "end",
    marginRight: "50px",
    width: "100%",
  },
});
