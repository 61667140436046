import React, { createContext, useState } from "react";

import { Me, TOKEN } from "../Config/constant";
import { SetDataToLocalStorage } from "../Utils/services";

interface MyContextData {
  token: string;
  setToken: (newToken: string, Me: any) => void;
  Me:
    | {
        id: number;
        mail: String;
        mdp: String;
        nom: String;
        token: String;
        typeidentite: number;
        client: {
          adresse: String;
          bloquer: Boolean;
          cp: String;
          grossiste: String;
          groupement: String;
          id: number;
          id_identite: number;
          ville: String;
          liste_telephone_client: {
            numero: String;
            type_telephone: number;
          }[];
        };
      }
    | null
    | undefined;
  logout: () => void;
}

interface IAuthProvider {
  children: React.ReactNode | JSX.Element;
}

const AuthContext = createContext<MyContextData>({
  token: "",
  setToken: () => {},
  logout: () => {},
  Me: null,
});

export const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [token, setData] = useState("");
  const [User, setMe] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setToken = (newToken: string, MyInfo: any) => {
    SetDataToLocalStorage(Me, MyInfo);
    SetDataToLocalStorage(TOKEN, newToken);
    setData(newToken);
    setMe(MyInfo);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = () => {
    localStorage.clear();
    setData("");
    setMe(null);
  };

  React.useEffect(() => {
    const Newtoken = localStorage.getItem(TOKEN);
    if (Newtoken) {
      setData(Newtoken);
    }
    const myInfo = localStorage.getItem(Me);
    if (myInfo) {
      setMe(JSON.parse(myInfo));
    }
  }, []);

  const value = React.useMemo(
    () => ({ token, setToken, Me: User, logout }),
    [User, token, setToken, logout],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
